<template>
  <CustomScroll>
    <div class="search-result">
      <CompanySearchItem
        v-for="(item, index) in suggestions"
        :key="index"
        :item="item"
        @onClick="$emit('onClick', $event)"
      />
    </div>
  </CustomScroll>
</template>

<script>
import CustomScroll from '~/components/elements/CustomScroll';
import CompanySearchItem from '~/components/profile/company/Search/Item';

export default {
  name: 'CompanySearch',
  components: {CompanySearchItem, CustomScroll},
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
}
</script>

