<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_center font_m font_bold">
          Добро пожаловать!
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon name="close" width="15" height="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <div class="font font_sm font_center sign-up_message">
        Введите свой пароль
      </div>
      <form class="sign-up__form" @submit.prevent="onSubmitPassword">
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Номер телефона
          </div>
          <InputTel
            key="login-phone"
            :value="loginPhoneNumber"
            class="sign-up__form-input"
            placeholder="Номер телефона"
            data-test="phone"
            is-disabled
            @onValidate="onValidateHandler"
          />
        </div>
        <div class="sign-up__form-control">
          <PasswordInput
            key="login-password"
            ref="passwordInput"
            v-model="password"
            class="sign-up__form-input font_title-m error"
            placeholder="Введите пароль"
            :error="passwordError"
            :show-error="passwordError.length > 0"
          >
            Пароль
          </PasswordInput>
        </div>
        <div class="font font_sm font_left font_grey mb_sm">
          <a href="#" class="sign-up__terms-link" @click="handleForgotPassword">Забыли пароль?</a>
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isSocialAuth"
            size="xl"
            full
            type="submit"
            :disabled="isDisabledNextButton"
            data-test="get-code"
            class="sign-up-page-button font_no-transform"
            @click="handleLoginClick"
          >
            Войти
          </Button>
        </div>
      </form>
    </div>

    <AuthModalFooter v-if="isSocialAuth" :disabled="isDisabledNextButton" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import * as amplitudeTracker from '@amplitude/analytics-browser';
import {v4} from 'uuid';
import env from '@/.env.json';
import { authModalPages } from '@/plugins/enums/auth';
import AuthModalFooter from '@/components/auth/Modal/Footer';
import Button from '@/components/elements/Button';
import InputTel from '~/components/elements/InputTel';
import PasswordInput from '@/components/elements/PasswordInput';
import {mergeOptions} from '~/plugins/enums/cart';
import amplitude from '~/plugins/amplitude';
import { cookieNames } from '~/plugins/enums/cookie';
import { MAX_AGE_API_SESSION } from '~/plugins/config';

export default {
  name: 'AuthModalSignInEnterPassword',
  components: {
    InputTel,
    AuthModalFooter,
    Button,
    PasswordInput,
  },
  data() {
    return {
      authModalPages,
      password: '',
      isDisabledGetCodeBtn: false,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
      passwordError: '',
    }
  },
  computed: {
    ...mapState('geolocation', ['isConfirmedCity']),
    ...mapState('auth', ['isOpenedAuthModal', 'loginPhoneNumber']),
    ...mapGetters({
      getCurrentAuthModalPage: 'auth/getCurrentAuthModalPage',
    }),
    isDisabledNextButton() {
      return this.password.length < 1;
    },
  },
  watch: {
    getCurrentAuthModalPage() {
      this.$refs.passwordInput.focus();
    },
  },
  mounted() {
    amplitude();
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms', 'loginByPassword', 'fetchUser']),
    ...mapActions('cart', [
      'getCartItems',
      'setMergedCart',
    ]),
    ...mapActions('profile', ['loadProfile']),
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async goToSendingSms() {
      try {
        this.isDisabledGetCodeBtn = true;
        const data = {
          phone: this.validatedPhone.number,
        };
        const apimsSession = v4();
        this.$cookies.set(cookieNames.API_SESSION, apimsSession, {
          maxAge: MAX_AGE_API_SESSION,
          path: '/',
        });
        localStorage.setItem(cookieNames.API_SESSION, apimsSession);
        const { isNew } = await this.loginByPhoneSendSms(data);
        this.setNewUser(isNew)
        this.setAcceptedPhone(this.validatedPhone.international);
        this.setCurrentAuthModalPage(authModalPages.ENTER_PASSWORD);
        amplitudeTracker.track('create_account', { create_account_date: new Date() });
      } catch (error) {
        console.log(error)
      } finally {
        this.isDisabledGetCodeBtn = false;
      }
    },
    onSubmitPassword() {

    },
    handleForgotPassword() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
    async handleLoginClick() {
      try {
        await this.loginByPassword({
          login: this.loginPhoneNumber,
          password: this.password,
        });
        // this.setNewUser(isNew);
        this.passwordError = '';
        this.setAcceptedPhone(this.validatedPhone.international);
        const isDifferentCarts = await this.$api.cart.checkCartsDifference();

        if (isDifferentCarts) {
          // вернуть если нужно дать пользователю выбор
          // this.viewMergeCartsModal = true;
          await this.$api.cart.sendMergeCartsOption(mergeOptions.BOTH_CARTS);
          this.setMergedCart(true);
        }

        this.getCartItems();
        this.loadProfile();
        await this.fetchUser();

        const user = await this.$api.auth.getUser();
        amplitudeTracker.setUserId(user.current_account.id);
        amplitudeTracker.track('login');
        amplitudeTracker.track('loginform_finish');

        if(this.isConfirmedCity) {
          this.closeModal();
        } else {
          this.setCurrentAuthModalPage(authModalPages.SELECT_LOCATION);
        }
      } catch (error) {
        if (error.config.url === '/v1/auth/loginByPassword') {
          this.passwordError = 'Неверный пароль';
        } else {
          this.passwordError = 'Произошла ошибка'
        }
      }
    },
  },
};
</script>
