<template>
  <div v-scroll-lock="isLocked" class="header__mobile-menu">
    <div class="mobile-menu">
      <HeaderMobileMenuList
        v-show="showCategories"
        :active-categories="activeCategories"
        @showCategories="handleShowCategories"
        @linkClick="onLinkClick"
      />
      <HeaderMobileMenuFirst v-show="!showCategories" @showCategories="handleShowCategories" />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';

import HeaderMobileMenuList from '~/layouts/components/Header/MobileMenu/List';
import HeaderMobileMenuFirst from '~/layouts/components/Header/MobileMenu/First';

export default {
  name: 'HeaderMobileMenu',
  components: {HeaderMobileMenuFirst, HeaderMobileMenuList},
  data() {
    return {
      activeCategories: {},
      isLocked: false,
    }
  },
  computed: {
    ...mapState('catalog', ['allCategories']),
    ...mapGetters('catalog', ['formattedAllCategories', 'getIsShowCategories']),
    showCategories: {
      get() {
        return this.getIsShowCategories;
      },
      set(value) {
        this.setIsShowCategories(value);
      },
    },
  },
  watch: {
    showCategories(value) {
      this.isLocked = !!value;
    },
  },
  methods: {
    handleShowCategories(evt, parent = 'root') {
      if (parent === 'root') {
        this.showCategories = true
        this.activeCategories = Object.assign({}, this.allCategories);
      } else {
        const parentCategory = this.formattedAllCategories[parent];
        if (parentCategory) {
          this.activeCategories = Object.assign({}, parentCategory.items);
        }
      }
    },
    onLinkClick() {
      this.$emit('linkClick')
    },
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
  },
}
</script>

