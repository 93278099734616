<template>
  <div
    class="password"
    :class="[
      { 'is-disabled': $attrs.disabled },
      { 'password--invalid': error },
    ]"
  >
    <label class="password__label" :for="inputId">
      <slot />
    </label>
    <div class="password__container">
      <input
        v-bind="$attrs"
        :id="inputId"
        ref="input"
        class="password__input"
        :value="value"
        :type="type"
        :autocomplete="autocomplete"
        :required="required"
        v-on="inputListeners"
      />
      <button
        v-if="!$attrs.disabled"
        class="password__btn-toggle"
        @mousedown.prevent
        @click.prevent="changePasswordState"
      >
        <svg-icon :name="showPassword ? 'show' : 'hide'" width="24" height="24" />
      </button>
    </div>
    <div
      v-if="showError"
      :id="`${inputId}-alert`"
      class="error-message password__error"
      role="alert"
    >
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </div>
  </div>
</template>

<script>
import {getRandomIntInclusive} from '~/plugins/generators';

export default {
  name: 'ElementPassword',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: true,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: getRandomIntInclusive(1, 100000),
      showPassword: false,
      autocomplete: 'off',
    };
  },
  computed: {
    inputId() {
      return `v-input-id-${this.id}`
    },
    type() {
      return this.showPassword ? 'text' : 'password';
    },
    inputListeners() {
      return Object.assign(
        {},
        this.$listeners,
        {
          input: this.input,
        },
      );
    },
  },
  methods: {
    changePasswordState() {
      this.showPassword = !this.showPassword;
    },
    input(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>
