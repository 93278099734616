<template>
  <component
    :is="getTag"
    v-bind="getProps"
    class="button"
    :class="classes"
    :download="download"
    v-on="handlers"
  >
    <span v-if="$slots.iconLeft" class="button__icon button__icon--left">
      <slot name="iconLeft"></slot>
    </span>

    <slot />

    <span v-if="$slots.iconRight" class="button__icon button__icon--right">
      <slot name="iconRight"></slot>
    </span>

    <span v-if="$slots.note" class="button__note">
      <slot name="note"></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: 'button',
      validator(value) {
        const types = ['button', 'reset', 'submit'];
        return types.includes(value);
      },
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: '',
    },
    download: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    social: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTag() {
      if (this.to && !this.download) {
        return 'nuxt-link';
      } else if (this.href) {
        return 'a';
      } else {
        return 'button';
      }
    },
    getProps() {
      if (this.to) {
        return {
          to: this.to,
        };
      } else if (this.href) {
        return {
          href: this.href,
        };
      } else {
        return {
          type: this.type,
          disabled: this.disabled,
        };
      }
    },
    handlers() {
      const keys = Object.keys(this.$listeners);
      const handlers = {};
      keys.forEach((k) => (handlers[k] = (e) => this.$emit(k, e)));
      return handlers;
    },
    classes() {
      return {
        [`button--${this.theme}`]: this.theme,
        [`button--${this.size}`]: this.size,
        [`button--${this.social}`]: this.social,

        'button--full': this.full,
        'button--multiline': this.multiline,
        'button--only-icon': this.onlyIcon,
        'button--round': this.round,
        'button--outline': this.outline,
      }
    },
  },
};
</script>
