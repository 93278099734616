<template>
  <div class="header-top__help">
    <button
      type="button"
      class="header-top__link header-top__link--toggle font font_m"
      :class="{
        'header-top__link--open': isOpenedSupportModal
      }"
      @click.stop="toggleSupportModal"
    >
      Помощь
    </button>

    <transition name="fade">
      <Support
        v-if="isOpenedSupportModal"
        v-click-outside="closeSupportModal"
        class="header-top__help-popup"
        :menu="menuHelp"
      />
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Support from '~/layouts/components/Header/Support';

export default {
  name: 'HeadereTopHelp',
  components: {
    Support,
  },
  data() {
    return {
      isOpenedSupportModal: false,
    }
  },
  computed: {
    ...mapGetters({
      menuHelp: 'getMenuHelp',
    }),
  },
  methods: {
    toggleSupportModal() {
      this.isOpenedSupportModal = !this.isOpenedSupportModal;
    },
    closeSupportModal() {
      this.isOpenedSupportModal = false
    },
  },
}
</script>
