var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-select",class:{
    'custom-select--numeric': _vm.isNumeric,
    'custom-select--float': _vm.float,
    'custom-select--active': _vm.$attrs.value,
    'custom-select--white': _vm.styleLight,
    'custom-select--invalid': _vm.error,
    'custom-select--sm': _vm.small,
  }},[(_vm.$slots.default)?_c('label',{staticClass:"custom-select__label",attrs:{"for":_vm.selectId}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"custom-select__message",attrs:{"role":"alert"}},[_vm._t("error",function(){return [_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]},{"error":_vm.error})],2):_vm._e(),_vm._v(" "),_c('client-only',[_c('v-select',{class:_vm.className,style:({
        '--customSelectLeftPadding': _vm.prependIcon?.width ? `${_vm.prependIcon.width + 16}px` : '16px',
      }),attrs:{"id":_vm.selectId,"options":_vm.options,"label":_vm.labelOptionName,"disabled":_vm.disabled,"clearable":false,"searchable":false,"append-icon":_vm.appendIcon,"placeholder":_vm.placeholder,"calculate-position":_vm.withPopper},on:{"input":function($event){return _vm.$emit('input', _vm.$attrs.value)}},scopedSlots:_vm._u([{key:"open-indicator",fn:function({ attributes }){return [_c(_vm.customIcon,_vm._b({tag:"component"},'component',attributes,false))]}}]),model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}},[_c('div',{style:({
          paddingTop: '16px',
          paddingBottom: '16px',
        }),attrs:{"slot":"no-options"},on:{"click":function($event){_vm.$refs.select.open = false}},slot:"no-options"},[_vm._v("\n        "+_vm._s(_vm.noOptionsText)+"\n      ")])]),_vm._v(" "),(_vm.prependIcon)?_c('svg-icon',{staticClass:"prependIcon",attrs:{"name":_vm.prependIcon.icon,"width":_vm.prependIcon.width,"height":_vm.prependIcon.height}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }