import { verificationCodeType } from '@/plugins/enums/auth';
import {
  profilePassportFactory,
  profileRequisitesFactory,
} from '~/plugins/helpers/utils';
import {billingIdentificationStatus} from '~/plugins/enums/profile';

export const state = () => ({
  profile: null,
  fullName: '',
  backPageParams: {},

  isProfileLoading: false,
  modalAddCompany: false,
});

export const getters = {
  getFullName: (state) => {
    if (state.profile) {
      const profile = state.profile;
      let fullName = '';
      if (profile.last_name) fullName += `${profile.last_name} `;
      if (profile.first_name) fullName += `${profile.first_name} `;
      if (profile.middle_name) fullName += profile.middle_name;
      return fullName.trim();
    }
    return '';
  },
  getFirstName: (state) => {
    if (state.profile) {
      const profile = state.profile;
      return profile.first_name;
    }
    return '';
  },
  getLastName: (state) => {
    if (state.profile) {
      const profile = state.profile;
      return profile.last_name;
    }
    return '';
  },
  getNameSurname: (state) => {
    if (state.profile) {
      const profile = state.profile;
      let fullName = '';
      if (profile.first_name) fullName += `${profile.first_name} `;
      if (profile.last_name) fullName += profile.last_name;
      return fullName.trim();
    }
    return '';
  },
  getUserPersonalDiscount: (state) => {
    return {
      discount: state.profile?.referral_personal_discount?.percent || null,
      promocode: state.profile?.referral_personal_discount?.promoCode || '',
    }
  },
  isOfferAccepted: (state) => state.profile?.is_offer_accepted,
};

export const mutations = {
  SET_PROFILE(state, payload) {
    state.profile = payload;
  },
  SET_PROFILE_LOADING(state, payload) {
    state.isProfileLoading = payload
  },
  UPDATED_PROFILE(state, payload) {
    state.profile.first_name = payload.first_name;
    state.profile.last_name = payload.last_name;
    state.profile.middle_name = payload.middle_name;
    state.profile.birthday = payload.birthday;
    state.profile.gender = payload.gender;
  },
  UPDATE_PHONE(state, payload) {
    state.profile.phone = payload;
  },
  UPDATE_EMAIL(state, payload) {
    state.profile.email = payload;
  },
  SET_BACK_PAGE_PARAMS(state, payload) {
    state.backPageParams = payload;
  },
  UPDATE_REQUISITES(state, payload) {
    const requisites = profileRequisitesFactory(payload)

    state.profile = {
      ...state.profile,
      ...requisites,
    }
  },
  UPDATE_PASSPORT(state, payload) {
    const passport = profilePassportFactory(payload)

    state.profile = {
      ...state.profile,
      ...passport,
    }
  },
  SET_OFFER_ACCEPT(state, payload) {
    state.profile.is_offer_accepted = payload
  },
  SET_MODAL_COMPANY(state, payload) {
    state.modalAddCompany = payload
  },
};

export const actions = {
  async loadProfile({ commit }) {
    try {
      commit('SET_PROFILE_LOADING', true)
      const result = await this.$api.profile.loadProfile();
      commit('SET_PROFILE', result);
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      commit('SET_PROFILE_LOADING', false)
    }
  },
  async updatePersonalData({ commit }, payload) {
    try {
      await this.$api.profile.updatePersonalData(payload);
      commit('UPDATED_PROFILE', payload);
    } catch (error) {
      throw new Error(`API Request Error (updatePersonalData): ${error}`);
    }
  },
  async sendCode({ commit }, payload) {
    try {
      await this.$api.profile.sendCode(payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateCredential({ commit }, payload) {
    switch (payload.type) {
      case verificationCodeType.PROFILE_PHONE:
        commit('UPDATE_PHONE', payload.value);
        break;
      case verificationCodeType.PROFILE_EMAIL:
        commit('UPDATE_EMAIL', payload.value);
        break;
    }

    try {
      await this.$api.profile.updateCredential(payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateAvatar({ commit, dispatch }, payload) {
    try {
      await this.$api.profile.changeProfileAvatar(payload)
      await dispatch('auth/updateUserNameAvatar', null, { root: true });
    } catch (error) {
      console.log(error)
    }
  },
  async deleteAvatar({dispatch}) {
    try {
      await this.$api.profile.deleteProfileAvatar()
      await dispatch('auth/updateUserNameAvatar', null, { root: true });
    } catch (error) {
      console.log(error)
    }
  },
  async updateRequisites({ state, commit }, payload = {}) {
    try {
      const requisites = profileRequisitesFactory(payload)

      await this.$api.profile.changeProfileLegalInfo({ ...requisites });

      commit('UPDATE_REQUISITES', payload);
    } catch (error) {
      console.log(error)
    }
  },
  async updatePassport({ state, commit }, payload = {}) {
    try {
      const passport = profilePassportFactory(payload)

      await this.$api.profile.changeProfileLegalInfo({ ...passport });

      commit('UPDATE_PASSPORT', payload);
    } catch (error) {
      console.log(error)
    }
  },
  async createCard(ctx, payload) {
    try {
      if (payload.identificationStatus === billingIdentificationStatus.SUCCESS) {
        await this.$api.profile.createBillCard(payload)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async setOfferAccept({ commit }, payload) {
    try {
      await this.$api.profile.setUserOfferAccept(payload)
      commit('SET_OFFER_ACCEPT', payload)
    } catch (error) {
      console.log(error)
    }
  },

  async getUserCompanies() {
    const { legalEntities } = await this.$api.profile.getCompanyAccount()

    if (!legalEntities) {
      return []
    }

    function prepareUserCompany(item) {
      return {
        id: item.id,
        name: item.legal_info_company_name,
        address: item.legal_info_company_address,
        inn: item.legal_info_inn,
        is_organization: true,
      }
    }

    return legalEntities.map(prepareUserCompany)
  },

  async addUserCompany({ dispatch }, payload) {
    try {
      const { other_accounts: otherAccounts } = await this.$api.profile.addCompanyAccount(payload)

      if (otherAccounts) {
        dispatch('auth/setUserCompanies', otherAccounts, { root: true });
      }
    } catch (e) {
      console.log(e)
    }
  },

  async setUserCompany({ dispatch }, item) {
    try {
      await this.$api.profile.changeCompanyAccount(item.id)
      dispatch('auth/setCurrentCompany', item, { root: true });

      return {}
    } catch (err) {
      return err?.response?.data?.errors || {}
    }
  },

  async removeUserCompany({ dispatch }, item) {
    if (!item.id) return

    try {
      const { other_accounts: otherAccounts } = await this.$api.profile.deleteCompanyAccount(item.id)

      if (otherAccounts) {
        dispatch('auth/setUserCompanies', otherAccounts, { root: true });
      }

      return {}
    } catch (err) {
      return err?.response?.data?.errors || {}
    }
  },
};


