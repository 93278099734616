<template>
  <div class="search__form">
    <label class="search__label" @click="onSearchIconClick">
      <svg-icon name="site-header/search-small" width="22" height="22" />
    </label>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'SearchIconButton',
  computed: {
    ...mapState('search', [ 'searchQuery']),
    searchString: {
      get() {
        return this.searchQuery
      },
      set(newValue) {
        this.setSearchQuery(newValue)
      },
    },
  },
  methods: {
    ...mapActions('search', ['onInputFocus', 'setSearchQuery', 'clearSearchResult', 'setIsOpenedSearch']),
    clearSearch() {
      this.clearSearchResult()
    },
    onFocusHandler() {
      this.onInputFocus()
      this.$emit('onFocus')
    },
    onKeyEnter() {
      this.$router.push({
        name: 'search',
        query: { search_string: this.searchString },
      });
      this.$emit('onEnter')
    },
    onSearchIconClick() {
      // this.$refs.searchInput.focus();
      this.setIsOpenedSearch(true)
    },
  },
}
</script>
<style scoped>
.search__label {
  cursor: pointer;
}
</style>
