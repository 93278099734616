var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"search-result__products search-products"},_vm._l((_vm.products),function(item,index){return _c('li',{key:index},[_c('NuxtLink',{attrs:{"to":{
        name: 'catalog.category.product',
        params: {
          category: item.categoryCodes[item.categoryCodes.length - 1],
          product: item.code,
        }
      }},nativeOn:{"click":function($event){return _vm.onClickHandler.apply(null, arguments)}}},[_c('SearchResultProductsItem',{attrs:{"item":item}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }