export const verificationCodeType = Object.freeze({
  REGISTRATION: 1,
  RESET_PASSWORD: 2,
  PROFILE_PHONE: 3,
  PROFILE_EMAIL: 4,
});

export const authModalPages = Object.freeze({
  INDEX: 'index',
  SIGN_UP: 'signUp',
  SIGN_UP_PHONE: 'signUpPhone',
  SIGN_UP_CODE: 'signUpCode',
  SELECT_LOCATION: 'signUpLocation',
  ENTER_PASSWORD: 'enterPassword',
  FORGOT_PASSWORD: 'forgotPassword',
  FORGOT_PASSWORD_CODE: 'forgotPasswordCode',
  SET_PASSWORD: 'setPassword',
  REGISTRATION: 'registration',
  REGISTRATION_CODE: 'registrationCode',

  SIGN_IN: 'signIn',
  SIGN_IN_EMAIL: 'signInEmail',
  RESTORE_PASSWORD: 'restorePassword',
});

