<template>
  <div>
    <ul class="header__menu-list">
      <li
        v-for="(menuItem, menuIdx) in menuMain"
        :key="`m-${menuIdx}`"
      >
        <MenuToggle
          v-if="menuItem.url === '/catalog'"
          v-model="showCategories"
          :hide-text-on-start="false"
          class="header-main__menu-toggle-desktop"
          @mouseenter="handleOpenMenu"
          @mouseleave="handleClearTimeout"
        >
          {{ menuItem.name }}
        </MenuToggle>
        <nuxt-link v-else :to="menuItem.url" @mouseenter.native="handleCloseMenu">
          {{ menuItem.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle.vue';

export default {
  name: 'HeaderMainMenu',
  components: {MenuToggle},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  computed: {
    ...mapGetters({
      menuMain: 'getMenuMain',
    }),
    ...mapGetters('catalog', [
      'getIsShowCategories',
    ]),
    showCategories: {
      get() {
        return this.getIsShowCategories;
      },
      set(value) {
        this.setIsShowCategories(value);
      },
    },
  },
  methods: {
    handleOpenMenu() {
      this.timeout = setTimeout(() => {
        this.setIsShowCategories(true);
      }, 200);
    },
    handleClearTimeout() {
      if (!this.getIsShowCategories) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      }
    },
    handleCloseMenu() {
      this.setIsShowCategories(false);
    },
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
  },
}
</script>
<style>
.header__menu-list {
  display: flex;
  gap: 20px;
  list-style: none;
  padding-top: 28px;
  padding-bottom: 28px;
  font-size: 16px;
  font-weight: 700;
  a {
    color: #282828;
  }
}
@media all and (max-width: 1024px) {
  .header__menu-list {
    display: none;
  }
}
</style>
