<template>
  <ul class="header-top__info">
    <li
      v-for="(link, idx) in links"
      :key="`info${idx}`"
      class="header-top__info-item"
    >
      <nuxt-link
        :to="link.to"
        class="header-top__link header-top__link--ico font font_m"
        :class="{
          [`header-top__link--ico-${link.icon}`]: true
        }"
      >
        {{ link.name }}
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderTopLinks',
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
}
</script>
