const middleware = {}

middleware['checkAuthAvailable'] = require('../middleware/checkAuthAvailable.js')
middleware['checkAuthAvailable'] = middleware['checkAuthAvailable'].default || middleware['checkAuthAvailable']

middleware['checkCartProducts'] = require('../middleware/checkCartProducts.js')
middleware['checkCartProducts'] = middleware['checkCartProducts'].default || middleware['checkCartProducts']

middleware['checkCartServices'] = require('../middleware/checkCartServices.js')
middleware['checkCartServices'] = middleware['checkCartServices'].default || middleware['checkCartServices']

middleware['checkProfile'] = require('../middleware/checkProfile.js')
middleware['checkProfile'] = middleware['checkProfile'].default || middleware['checkProfile']

middleware['checkReferalStatus'] = require('../middleware/checkReferalStatus.js')
middleware['checkReferalStatus'] = middleware['checkReferalStatus'].default || middleware['checkReferalStatus']

middleware['createCard'] = require('../middleware/createCard.js')
middleware['createCard'] = middleware['createCard'].default || middleware['createCard']

middleware['loginBySocial'] = require('../middleware/loginBySocial.js')
middleware['loginBySocial'] = middleware['loginBySocial'].default || middleware['loginBySocial']

middleware['redirectAllToMaintenance'] = require('../middleware/redirectAllToMaintenance.js')
middleware['redirectAllToMaintenance'] = middleware['redirectAllToMaintenance'].default || middleware['redirectAllToMaintenance']

middleware['referralLink'] = require('../middleware/referralLink.js')
middleware['referralLink'] = middleware['referralLink'].default || middleware['referralLink']

middleware['user-ip'] = require('../middleware/user-ip.js')
middleware['user-ip'] = middleware['user-ip'].default || middleware['user-ip']

export default middleware
