export const state = () => ({
  code: '',
  modalState: false,
  // this ensures the functionality of stories
  // TODO: figure out what is the dependency for the stories, and fix it
  quickLookModalState: false,
  isPending: true,
  isHiddenPending: true,
})

export const mutations = {
  SET_QUICKLOOK_CODE(state, payload) {
    state.code = payload
  },
  SET_QUICKLOOK_PENDING(state, payload) {
    state.isPending = payload
  },
  SET_QUICKLOOK_HIDDEN_PENDING(state, payload) {
    state.isHiddenPending = payload
  },
  SET_QUICKLOOK_STATE(state, payload) {
    state.modalState = payload
  },
}

export const actions = {
  async showQuickProduct({ state, commit, dispatch }, { code, category, hidden = false }) {
    commit('SET_QUICKLOOK_CODE', code)
    commit('SET_QUICKLOOK_STATE', true)

    if (!hidden) {
      commit('SET_QUICKLOOK_PENDING', true)
    } else {
      commit('SET_QUICKLOOK_HIDDEN_PENDING', true)
    }


    try {
      await dispatch('product/fetchProduct', { code, category }, { root: true });
    } catch (e) {
      dispatch('hideQuickProduct')
      console.log(e)
    } finally {
      commit('SET_QUICKLOOK_PENDING', false)
      commit('SET_QUICKLOOK_HIDDEN_PENDING', false)
    }
  },
  hideQuickProduct({ commit }) {
    commit('SET_QUICKLOOK_STATE', false)
    commit('SET_QUICKLOOK_CODE', '')
    commit('SET_QUICKLOOK_PENDING', false)
    commit('SET_QUICKLOOK_HIDDEN_PENDING', false)
  },
}
