<template>
  <div class="header__logo-container">
    <Logo class="header__logo" custom-logo="/assets/images/logo/logo-no-text.svg" />
  </div>
</template>
<script>
import Logo from '~/components/elements/Logo.vue';

export default {
  name: 'HeaderLogo',
  components: {
    Logo,
  },
}
</script>
<style>
.header__logo-container {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 0;
}
.header__logo {
  height: 35px;
}
@media (max-width: 1023px) {
  .header__logo-container {
    padding-top: 8px;
    padding-bottom: 0;
  }
}
</style>
