export default axios => ({
  getLogo() {
    try {
      return axios.$get('/v1/content/logotype');
    } catch (error) {
      throw new Error(`API Request Error (getLogo): ${error}`);
    }
  },
  getMenu() {
    try {
      return axios.$get('/v1/content/menus');
    } catch (error) {
      throw new Error(`API Request Error (getMenu): ${error}`);
    }
  },
  getBanners(typeCode = 'catalog', path = '') {
    try {
      return axios.$get('/v1/content/banners', {
        params: { typeCode, path },
      });
    } catch (error) {
      throw new Error(`API Request Error (getBanners): ${error}`);
    }
  },
  getPage(url) {
    try {
      return axios.$get('/v1/content/pages', {
        params: {
          url,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPages): ${error}`);
    }
  },
  getPageMeta(path = '') {
    try {
      return axios.$get('/v1/meta', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPageMeta): ${error}`);
    }
  },
  getPageConstructor(path = '') {
    try {
      return axios.$get('/v1/landing-widget', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPageConstructor): ${error}`);
    }
  },
  getInstagramFeed(path = '') {
    try {
      return axios.$get('https://api-prod.viled.kz/tizilimer/api/v1/instagram/cdv', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getInstagramFeed): ${error}`);
    }
  },
});
