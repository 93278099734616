<template>
  <div>
    <CustomModal
      v-if="!mqIsMobile || (mqIsMobile && getCurrentAuthModalPage !== authModalPages.REGISTRATION)"
      id="auth-modal"
      :empty="true"
      :visible="isOpenedAuthModal"
      @close="closeModal()"
      @closeOverlay="closeModalOverlay()"
    >
      <template #content>
        <SignInPhone
          v-if="getCurrentAuthModalPage === authModalPages.INDEX"
          @closeModal="closeModal()"
        />
        <SignUpPhone
          v-if="getCurrentAuthModalPage === authModalPages.SIGN_UP_PHONE"
          @closeModal="closeModal()"
        />
        <SignUpCode
          v-else-if="getCurrentAuthModalPage === authModalPages.SIGN_UP_CODE"
          @closeModal="closeModal()"
        />
        <AuthModalSignInEnterPassword
          v-else-if="getCurrentAuthModalPage === authModalPages.ENTER_PASSWORD"
          @closeModal="closeModal()"
        />
        <AuthModalSignInForgotPassword
          v-else-if="getCurrentAuthModalPage === authModalPages.FORGOT_PASSWORD"
          @closeModal="closeModal()"
        />
        <AuthModalForgotPasswordCode
          v-else-if="getCurrentAuthModalPage === authModalPages.FORGOT_PASSWORD_CODE"
          @closeModal="closeModal()"
        />
        <SignInSetPassword
          v-else-if="getCurrentAuthModalPage === authModalPages.SET_PASSWORD"
          @closeModal="closeModal()"
        />
        <AuthModalSignUpRegistration
          v-else-if="getCurrentAuthModalPage === authModalPages.REGISTRATION"
          @closeModal="closeModal()"
        />
        <Location
          v-else-if="getCurrentAuthModalPage === authModalPages.SELECT_LOCATION"
          @closeModal="closeModal()"
        />
        <AuthModalRegistrationCode
          v-else-if="getCurrentAuthModalPage === authModalPages.REGISTRATION_CODE"
        />
      </template>
    </CustomModal>
    <CustomModalMobile
      v-else-if="mqIsMobile && getCurrentAuthModalPage === authModalPages.REGISTRATION"
      id="auth-modal"
      :empty="true"
      :visible="isOpenedAuthModal"
      @close="closeModal()"
      @closeOverlay="closeModalOverlay()"
    >
      <template #content>
        <AuthModalSignUpRegistrationMobile
          v-if="getCurrentAuthModalPage === authModalPages.REGISTRATION"
          @closeModal="closeModal()"
        />
      </template>
    </CustomModalMobile>
  </div>
</template>

<script>
import './index.css';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { authModalPages } from '@/plugins/enums/auth';
import { MODAL_FADE_DURATION } from '@/plugins/config';

import CustomModal from '@/components/elements/CustomModal';
import SignUpPhone from '@/components/auth/Modal/SignUp/Phone';
import SignInPhone from '@/components/auth/Modal/SignIn/Phone';
import SignUpCode from '@/components/auth/Modal/SignUp/Code';
import Location from '@/components/auth/Location';
import AuthModalSignInEnterPassword from '@/components/auth/Modal/SignIn/EnterPassword';
import AuthModalSignInForgotPassword from '~/components/auth/Modal/SignIn/ForgotPassword/index.vue';
import AuthModalForgotPasswordCode from '~/components/auth/Modal/SignIn/ForgotPasswordCode/index.vue';
import SignInSetPassword from '~/components/auth/Modal/SignIn/SetPassword/index.vue';
import AuthModalSignUpRegistration from '~/components/auth/Modal/SignUp/Registration/index.vue';
import AuthModalRegistrationCode from '~/components/auth/Modal/SignIn/RegistrationCode/index.vue';
import CustomModalMobile from '~/components/elements/CustomModalMobile.vue';
import AuthModalSignUpRegistrationMobile from '~/components/auth/Modal/SignUp/RegistrationMobile/index.vue';

export default {
  name: 'AuthModal',
  components: {
    AuthModalSignUpRegistrationMobile,
    CustomModalMobile,
    AuthModalRegistrationCode,
    AuthModalSignUpRegistration,
    SignInSetPassword,
    AuthModalForgotPasswordCode,
    AuthModalSignInForgotPassword,
    AuthModalSignInEnterPassword,
    CustomModal,
    Location,
    SignUpPhone,
    SignInPhone,
    SignUpCode,
  },
  data() {
    return {
      modalFadeDuration: MODAL_FADE_DURATION,
      authModalPages,
    };
  },
  // watch: {
  //   mqIsMobile(val) {
  //     console.log('mqIsMobile', val);
  //   },
  //   getCurrentAuthModalPage(val) {
  //     console.log('getCurrentAuthModalPage', val);
  //   },
  //   isOpenedAuthModal(val) {
  //     console.log('isOpenedAuthModal', val);
  //   },
  // },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapGetters({
      getCurrentAuthModalPage: 'auth/getCurrentAuthModalPage',
    }),
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'mobileXs';
    },
  },
  mounted() {
    this.attachEscKeyUpEvent();
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    closeModal() {
      this.setIsOpenedAuthModal(false);
      setTimeout(this.resetCurrentAuthModalPage, this.modalFadeDuration);

      this.$emit('closeModal')
    },
    closeModalOverlay() {
      if (this.getCurrentAuthModalPage !== authModalPages.REGISTRATION) {
        this.setIsOpenedAuthModal(false);
        setTimeout(this.resetCurrentAuthModalPage, this.modalFadeDuration);

        this.$emit('closeModal');
      }
    },
    resetCurrentAuthModalPage() {
      this.setCurrentAuthModalPage(null);
    },
    attachEscKeyUpEvent() {
      const context = this;
      document.addEventListener('keyup', (event) => {
        if (event.code === 'Escape') {
          context.closeModal();
        }
      });
    },
  },
};
</script>
