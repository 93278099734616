import * as amplitude from '@amplitude/analytics-browser';
export default function() {
  amplitude.init('7dd654484d6494daf7a182e083a2a760', {
    minIdLength: 2,
    defaultTracking: {
      attribution: {
        excludeReferrers: ['https://epay.homebank.kz/', 'https://cdv.kz/'],
        resetSessionOnNewCampaign: true,
      },
      pageViews: true,
      sessions: true,
      formInteractions: true,
    },
  });
}
