const items = [
  {
    thumbnailUrl: '/Stories/Dior/dior.svg',
    url: '/dior/',
    title: 'Dior',
    slides: [
      {
        video: '/Stories/Dior/dior_jadore_lor.webm',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Estee_Lauder/EL-black-300x300.png',
    url: '/brands/estee-lauder/',
    title: 'Estee Lauder',
    slides: [
      {
        image: '/Stories/Estee_Lauder/el_bm_1.webp',
      },
      {
        image: '/Stories/Estee_Lauder/el_bm_2.webp',
      },
      {
        image: '/Stories/Estee_Lauder/el_bm_3.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/MAC/MAC-black-300x300.png',
    url: '/mac/',
    title: 'MAC',
    slides: [
      {
        image: '/Stories/MAC/mac_lki_1.webp',
      },
      {
        image: '/Stories/MAC/mac_lki_2.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Jo_Malone_London/JML-black-300x300.png',
    url: '/jo-malone-london/',
    title: 'Jo Malone London',
    slides: [
      {
        image: '/Stories/Jo_Malone_London/jm_epf_1.webp',
      },
      {
        image: '/Stories/Jo_Malone_London/jm_epf_2.webp',
      },
      {
        image: '/Stories/Jo_Malone_London/jm_epf_3.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Tom_Ford/TF-black-300x300.png',
    url: '/tom-ford/',
    title: 'Tom Ford',
    slides: [
      {
        image: '/Stories/Tom_Ford/tf_bo_1.webp',
      },
      {
        image: '/Stories/Tom_Ford/tf_ls_1.webp',
      },
      {
        image: '/Stories/Tom_Ford/tf_ow_1.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/La_Mer/LM-black-300x300.png',
    url: '/brands/la-mer/',
    title: 'La Mer',
    slides: [
      {
        image: '/Stories/La_Mer/lm_msc_1.webp',
      },
      {
        image: '/Stories/La_Mer/lm_mb_1.webp',
      },
      {
        image: '/Stories/La_Mer/lm_mdv_1.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Kilian/KP-black-300x300.png',
    url: '/brands/kilian-paris/',
    title: 'Kilian',
    slides: [
      {
        image: '/Stories/Kilian/kil_liq_1.webp',
      },
      {
        image: '/Stories/Kilian/kil_roi_1.webp',
      },
      {
        image: '/Stories/Kilian/kil_lv_1.webp',
      },
      {
        image: '/Stories/Kilian/kil_as_1.webp',
      },
      {
        image: '/Stories/Kilian/kil_ab_1.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Darphin/DA-black-300x300.png',
    url: '/darphin/',
    title: 'Darphin',
    slides: [
      {
        image: '/Stories/Darphin/dp_sp_1.webp',
      },
      {
        image: '/Stories/Darphin/dp_sp_2.webp',
      },
      {
        image: '/Stories/Darphin/dp_sp_3.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Frederic_Malle/FM-black-300x300.png',
    url: '/frederic-malle/',
    title: 'Frederic Malle',
    slides: [
      {
        image: '/Stories/Frederic_Malle/fm_pof_1.webp',
      },
      {
        image: '/Stories/Frederic_Malle/fm_pof_2.webp',
      },
      {
        image: '/Stories/Frederic_Malle/fm_pof_3.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Clinique/CL-black-300x300.png',
    url: '/brands/clinique/',
    title: 'Clinique',
    slides: [
      {
        image: '/Stories/Clinique/cl_girl_1.webp',
      },
      {
        image: '/Stories/Clinique/cl_msi_1.webp',
      },
      {
        image: '/Stories/Clinique/cl_msi_2.webp',
      },
      {
        image: '/Stories/Clinique/cl_msi_3.webp',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/La_Sultane_De_Saba/sultane_de_saba_logo.svg',
    url: '/brands/la-sultane-de-saba/',
    title: 'La Sultane De Saba',
    slides: [
      {
        image: '/Stories/La_Sultane_De_Saba/lsds_ve_1.webp',
      },
      {
        image: '/Stories/La_Sultane_De_Saba/lsds_cr_1.webp',
      },
      {
        image: '/Stories/La_Sultane_De_Saba/lsds_cr_2.webp',
      },
      {
        video: '/Stories/La_Sultane_De_Saba/lsds_red_1.webm',
      },
    ],
  },
  {
    thumbnailUrl: '/Stories/Rated_Green/RATED_GREEN_LOGO.svg',
    url: '/brands/rated-green/',
    title: 'Rated Green',
    slides: [
      {
        image: '/Stories/Rated_Green/rg_rm_1.webp',
      },
      {
        image: '/Stories/Rated_Green/rg_rm_2.webp',
      },
      {
        image: '/Stories/Rated_Green/rg_rt_1.webp',
      },
      {
        image: '/Stories/Rated_Green/rg_hs_1.webp',
      },
    ],
  },
];

export const state = () => ({
  items,
  isOpen: false,
  // текущий дочерний слайд
  currentSlide: 0,
  initialIndex: 0,
});

export const getters = {
  getItems: (state) => state.items,
  getIsOpen: (state) => state.isOpen,
  getCurrentSlide: (state) => state.currentSlide,
  getInitialIndex: (state) => state.initialIndex,
}

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  DELETE_ITEMS(state) {
    state.items = [];
  },
  SET_IS_OPEN(state, payload) {
    state.isOpen = payload;
  },
  SET_CURRENT_SLIDE(state, payload) {
    state.currentSlide = payload;
  },
  SET_INITIAL_INDEX(state, payload) {
    state.initialIndex = payload;
  },
}

export const actions = {};
