<template>
  <ul class="menu__list header-bottom__menu-list">
    <li class="menu__item">
      <MenuToggle v-model="showCategories" :hide-text-on-start="false">
        Категории
      </MenuToggle>
    </li>

    <li
      v-for="(menuItem, menuIdx) in menuMain"
      :key="`m-${menuIdx}`"
      class="menu__item"
    >
      <div
        v-if="isCurrentRoute(menuItem.url)"
        class="menu__link font_sm font_uppercase"
      >
        {{ menuItem.name }}
      </div>

      <nuxt-link
        v-else
        :to="menuItem.url"
        class="menu__link font_sm font_uppercase"
      >
        {{ menuItem.name }}
      </nuxt-link>
    </li>
    <li class="menu__item menu__item--scroll">
      <div class="menu__item-scroll">
        <button
          data-test="show-search"
          aria-label="Показать поиск"
          @click="showSearch"
        >
          <svg-icon name="search" width="21" height="21" fill="#ffffff" />
        </button>
      </div>

      <template v-if="hasSession">
        <client-only>
          <div class="menu__item-scroll">
            <nuxt-link
              :to="{ name: 'profile' }"
            >
              <svg-icon name="account-middle" width="24" height="24" />
            </nuxt-link>
          </div>
        </client-only>
      </template>
      <div v-else class="menu__item-scroll">
        <button
          data-test="log-in"
          aria-label="Войти"
          @click="openAuthModal"
        >
          <svg-icon name="account-middle" width="24" height="24" />
        </button>
      </div>

      <div class="menu__item-scroll">
        <nuxt-link
          aria-label="Открыть корзину"
          :to="{ name: 'cart' }"
        >
          <span class="header-user__cart-count">
            {{ getCartAllItemsCount.all }}
          </span>
          <svg-icon name="cart" width="27" height="24" />
        </nuxt-link>
      </div>

      <HeaderBottomSearch v-if="isScrollOffset" />
    </li>
  </ul>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle';
import HeaderBottomSearch from '~/layouts/components/Header/Bottom/Search';

export default {
  name: 'HeaderBottomMenu',
  components: {
    HeaderBottomSearch,
    MenuToggle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ...mapState('catalog', ['isShowCategories']),
    }
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
    }),
    showCategories: {
      get() {
        return this.isShowCategories;
      },
      set(value) {
        this.setIsShowCategories(value);
      },
    },
  },
  watch: {
    showCategories(value) {
      if (value) {
        this.setIsOpenedSearch(false);
      }
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch' ]),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
    isCurrentRoute (url) {
      const isCatalogUrl = url === '/catalog';

      return url === this.$route.path
        || isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product;
    },
    showSearch() {
      this.setIsOpenedSearch(true);
      this.showCategories = false;
    },
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
  },
}
</script>
