<template>
  <div
    class="fade-box"
    :class="{
      'fade-box-hidden': (!isVisible && !isFadingIn && !isFadingOut) || !display,
      'fade-box-invisible fade-box-transition': !isVisible && (isFadingIn || isFadingOut),
      'fade-box-transition': isVisible && !isFadingIn && !isFadingOut
    }"
  >
    <div
      class="fav-container"
    >
      <div class="fav-image">
        <img :src="imgSrc" :alt="name" />
      </div>
      <div class="fav-text">
        <div class="fav-message">
          {{ message }}
        </div>
        <div class="fav-brandName">
          {{ brandName }}
        </div>
        <div class="fav-name">
          {{ name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: 'AddedFavCartPopup',
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      brandName: '',
      message: '',
      imgSrc: '',
      isVisible: false,
      isFadingIn: false,
      isFadingOut: false,
    };
  },
  computed: {
    ...mapGetters('favorites', ['getLastFavoriteAdded']),
    ...mapGetters('cart', ['getLastCartAdded']),
  },
  watch: {
    getLastFavoriteAdded(newVal) {
      this.name = newVal.name;
      this.brandName = newVal.brandName;
      this.message = 'Добавлено в избранное';
      this.imgSrc = newVal.imgSrc;

      const scope = this;
      scope.isFadingIn = true;
      setTimeout(() => {
        scope.isVisible = true;
        scope.isFadingIn = false;
      }, 500);
      setTimeout(() => {
        scope.isVisible = false;
        scope.isFadingOut = true;
      }, 2500);
      setTimeout(() => {
        scope.isFadingOut = false;
        scope.name = '';
        scope.brandName = '';
        scope.message = '';
        scope.imgSrc = '';
      }, 3000);
    },
    getLastCartAdded(newVal) {
      this.name = newVal.name;
      this.brandName = newVal.brandName;
      this.message = 'Добавлено в корзину';
      this.imgSrc = newVal.imgSrc;
      const scope = this;
      scope.isFadingIn = true;
      setTimeout(() => {
        scope.isVisible = true;
        scope.isFadingIn = false;
      }, 500);
      setTimeout(() => {
        scope.isVisible = false;
        scope.isFadingOut = true;
      }, 2500);
      setTimeout(() => {
        scope.isFadingOut = false;
        scope.name = '';
        scope.brandName = '';
        scope.message = '';
        scope.imgSrc = '';
      }, 3000);
    },
  },
}
</script>
<style scoped>
.fav-container {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 18px 0px rgba(25, 25, 25, 0.2);
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999999;
  background-color: #ffffff;
  opacity: 1;
  @media all and (max-width: 1279px) {
    position: fixed;
    top: auto;
    bottom: 16px;
    left: 16px;
    width: calc(100vw - 32px);
  }
}
.fav-image {
  width: 112px;
  height: 112px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.fav-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  color: #232528;
  width: 360px;
}
.fav-message {
  font-size: 16px;
  font-weight: 300;
}
.fav-brandName {
  font-size: 16px;
  font-weight: 500;
}
.fav-name {
  font-size: 12px;
  font-weight: 300;
}

.fade-box {
  opacity: 1;
}

.fade-box-hidden {
  opacity: 0;
  display: none;
}

.fade-box-invisible {
  opacity: 0;
}

/* ensure box is displayed block during the transition  */
.fade-box-transition.fade-box-hidden {
  display: block;
  opacity: 0;
}

.fade-box-transition {
  transition: opacity 0.5s ease;
  display: block;
}
</style>
