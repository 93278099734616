<template>
  <div class="video-icon">
    <SvgIcon class="video-icon__icon" name="play" width="64" height="64" />
  </div>
</template>

<script>
export default {
  name: 'VideoIcon',
}
</script>

