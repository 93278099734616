<template>
  <div class="product-images-slider">
    <div
      ref="swiperMain"
      v-swiper:sliderMain="sliderOptionsMain"
      class="product-images-slider__main swiper product-images-slider-main"
    >
      <ul class="swiper-wrapper product-images-slider-main__wrapper">
        <li
          v-for="(image, idx) in pngImages"
          :key="`slider-${idx}`"
          class="swiper-slide product-images-slider-main__slide"
          @click="openImage(idx)"
        >
          <CustomImage
            class="product-images-slider-main__image"
            :class="{ 'product-images-slider-main__image_video' : image.type === 'youtube' }"
            :image-src="image.src"
            :width="imageSizesPrimary[0]"
            :height="imageSizesPrimary[1]"
            :alt="alt"
            loading="default"
          />
          <VideoIcon v-if="image.type === 'youtube'" />
        </li>
      </ul>
    </div>
    <CoolLightBox
      :items="galleryItems"
      :index="imageOpenIndex"
      @close="imageOpenIndex = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import { directive } from 'vue-awesome-swiper';
import CoolLightBox from 'vue-cool-lightbox';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import 'swiper/css/swiper.css'
import './index.css';
import CustomImage from '~/components/elements/CustomImage';
import {
  generateFileOriginalPath,
  generateYoutubePreview,
  generateYoutubeVideoSourcePath,
  getImageAllFormats,
} from '~/plugins/helpers/file';
import VideoIcon from '~/components/elements/VideoIcon';

export default {
  name: 'ProductSliderImages',
  components: {
    VideoIcon,
    CustomImage,
    CoolLightBox,
  },
  directives: {
    swiper: directive,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    imageSizesPrimary:{
      type: Array,
      default: () => [240, 240],
    },
    alt: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sliderOptionsMain: {
        slidesPerView: 1,
      },
      imageOpenIndex: null,
    }
  },
  computed: {
    pngImages() {
      let slides = []

      slides = this.images.map(imageId => {
        const image = getImageAllFormats(imageId, this.imageSizesPrimary[0], this.imageSizesPrimary[1])
        return {
          src: image.png[1],
          type: 'image',
        }
      });

      if (this.video) {
        slides.push({
          src: generateYoutubePreview(this.video),
          type: 'youtube',
        })
      }

      return slides
    },
    galleryItems() {
      let slides = []

      slides = this.images.map(item => {
        return {
          src: generateFileOriginalPath(item),
        }
      })

      if (this.video) {
        slides.push({ src: generateYoutubeVideoSourcePath(this.video) })
      }

      return slides
    },
  },
  methods: {
    openImage(index) {
      this.imageOpenIndex = index;
    },
  },
}
</script>
