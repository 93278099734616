<template>
  <transition name="slide">
    <div
      v-if="popUpState"
      v-click-outside="closePopupHandler"
      class="popup"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomPopup',
  props: {
    popUpState: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePopupHandler(e) {
      this.$emit('closePopUp', e)
    },
  },
}
</script>

