<template>
  <div class="header-user__detail-wrapper">
    <ul class="header-user__detail">
      <li class="header-user__detail-item">
        <ProfileInitials
          class="header-user__detail-initials"
          :initials="profileInitials.initials"
          :name="profileInitials.name"
          :initials-icon="profileInitials.icon"
          name-class="font_bold font_uppercase"
          :icon-class="profileInitials.iconClass"
        />
      </li>

      <li class="header-user__detail-item">
        <nuxt-link
          to="/profile"
          class="header-user__detail-link font_m"
          data-test="profile-menu-personal"
        >
          <svg-icon name="faceid" height="24" width="24" />
          <span class="font_underline header-user__detail-link-text">Профиль</span>
        </nuxt-link>
      </li>

      <li class="header-user__detail-item">
        <nuxt-link
          :to="{ name: 'profile.orders' }"
          class="header-user__detail-link font_m"
          data-test="profile-menu-orders"
        >
          <svg-icon name="orders" height="24" width="24" />
          <span class="font_underline header-user__detail-link-text">Мои заказы</span>
        </nuxt-link>
      </li>

      <li class="header-user__detail-item">
        <nuxt-link
          :to="{ name: 'profile.messages' }"
          class="header-user__detail-link font_m"
          data-test="profile-menu-messages"
        >
          <svg-icon name="messages" height="24" width="24" />
          <span class="font_underline header-user__detail-link-text">Сообщения</span>
        </nuxt-link>
      </li>

      <!--      <li v-if="isUserReferral" class="header-user__detail-item">-->
      <!--        <nuxt-link-->
      <!--          :to="{ name: 'profile.referal' }"-->
      <!--          class="header-user__detail-link font_m"-->
      <!--          data-test="profile-menu-messages"-->
      <!--        >-->
      <!--          <span class="font_underline header-user__detail-link-text">Мой бизнес</span>-->
      <!--        </nuxt-link>-->
      <!--      </li>-->

      <li class="header-user__detail-item header-user__detail-item_logout">
        <svg-icon name="logout" height="24" width="24" />
        <button
          class="header-user__detail-link font_m header-user__detail-link-text profile-menu-logout"
          data-test="profile-menu-logout"
          @click="onLogout"
        >
          Выйти
        </button>
      </li>

      <!--      <li class="header-user__detail-item header-company">-->
      <!--        <div class="header-company__header">-->
      <!--          <div class="header-company__title font font_title-m font_bold">-->
      <!--            Мои компании-->
      <!--          </div>-->
      <!--          &lt;!&ndash;          <div class="header-company__settings font_underline">&ndash;&gt;-->
      <!--          &lt;!&ndash;            Настройки&ndash;&gt;-->
      <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
      <!--          <NuxtLink-->
      <!--            class="header-company__settings font_underline"-->
      <!--            data-test="header-company__settings"-->
      <!--            :to="{ name: 'profile.company' }"-->
      <!--          >-->
      <!--            Настройки-->
      <!--          </NuxtLink>-->
      <!--        </div>-->
      <!--        <div class="header-company__body">-->
      <!--          <ProfileInitials-->
      <!--            v-for="(item, index) in userCompanies"-->
      <!--            :key="index"-->
      <!--            class="header-company__item"-->
      <!--            :name="item.name"-->
      <!--            initials-icon="company"-->
      <!--            name-class="profile-initials__name_hover"-->
      <!--            icon-class="profile-initials__icon_inverse"-->
      <!--            @click="setCurrentCompany(item)"-->
      <!--          />-->
      <!--          <div-->
      <!--            class="header-company__item header-company__item-new"-->
      <!--            @click="onAddCompanyHandler"-->
      <!--          >-->
      <!--            <div class="header-company__item-icon">-->
      <!--              <SvgIcon name="plus" width="14" height="14" />-->
      <!--            </div>-->
      <!--            <span class="header-company__item-text font">Добавить новую компанию</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </li>-->

      <li v-if="isAccountOrganization" class="header-user__detail-account">
        <ProfileInitials
          v-for="(item, index) in userAccounts"
          :key="index"
          class="header-user__detail-user"
          :initials="getAvatarPlaceholder"
          :name="userName"
          name-class="profile-initials__name_hover"
          icon-class="profile-initials__icon_border"
          @click="setCurrentCompany(item)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import ProfileInitials from '~/components/profile/Initials';

export default {
  name: 'HeaderMainActionsAccountMenu',
  components: {ProfileInitials},
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      getAvatarPlaceholder: 'auth/getAvatarPlaceholder',
      currentCompany: 'auth/currentCompany',
      getUserCompanies: 'auth/getUserCompanies',
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
    isUserReferral() {
      return this.user?.referralPartner
    },
    userName() {
      const firstName = this.user?.firstName || ''
      const lastName = this.user?.lastName || ''

      return `${firstName} ${lastName}`
    },
    profileInitials() {
      return {
        initials: !this.isAccountOrganization ? this.getAvatarPlaceholder : '',
        icon: this.isAccountOrganization ? 'company' : '',
        iconClass: this.isAccountOrganization ? 'profile-initials__icon_inverse' : 'profile-initials__icon_border',
        // по запросу /v1/auth/user-name-avatar
        name: this.isAccountOrganization ? this.currentCompany?.name || '' : this.userName,
      }
    },
    userCompanies() {
      return this.getUserCompanies.filter(item => item.is_organization)
    },
    userAccounts() {
      return this.getUserCompanies.filter(item => !item.is_organization)
    },
  },
  methods: {
    ...mapActions({
      setUserCompany: 'profile/setUserCompany',
    }),
    ...mapActions('auth', ['logout']),
    ...mapMutations('profile', {
      setModalCompany: 'SET_MODAL_COMPANY',
    }),
    async onLogout() {
      this.isPendingLogout = true;
      await this.logout();
      this.isPendingLogout = false;
      if (this.$route.meta.availableAuthorized || this.$route.matched.some((item) => item.meta.availableAuthorized)) {
        this.$router.push('/');
      }
    },
    async setCurrentCompany(item) {
      await this.setUserCompany(item)
    },
    onAddCompanyHandler() {
      this.setModalCompany(true)
    },
  },
}
</script>

