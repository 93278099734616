export const phoneMaskOptions = Object.freeze({
  prefix: '+7',
  delimiters: [' ', ' ', '-', '-'],
  blocks: [2, 3, 3, 2, 2],
  numericOnly: true,
});
export const serialNumberMaskOptions = Object.freeze({
  delimiters: [' '],
  blocks: [4, 6],
  numericOnly: true,
});

export const codeMaskOptions = Object.freeze({
  blocks: [4],
  numericOnly: true,
});

export const snilsMaskOptions = Object.freeze({
  delimiters: ['-', '-', ' '],
  blocks: [3, 3, 3, 2],
  numericOnly: true,
});

export const yaMapSettings = Object.freeze({
  apiKey: 'fff2295f-9b6e-45b1-be7e-5bb163c84513',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
});

export const yaMapOptions = Object.freeze({
  yandexMapDisablePoiInteractivity: true,
  suppressMapOpenBlock: true,
});

export const yaMapControls = [
  'zoomControl',
];

export const yaShareServices = [
  'vkontakte',
  'facebook',
  'twitter',
  'telegram',
];
