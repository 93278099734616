import search from './search.js';
import catalog from './catalog.js';
import product from './product.js';
import profile from './profile.js';
import cart from './cart.js';
import checkout from './checkout.js';
import auth from './auth.js';
import geolocation from './geolocation.js';
import general from './general.js';
import giftcard from './giftcard.js';
import services from './services.js';
import favorites from './favorites.js';
import reviews from './reviews.js';
import brands from './brands.js';
import emex from './emex';
import kompra from './kompra';

import {xml} from '~/Service/XML';

const apiFactory = (axios) => ({
  cart: cart(axios),
  search: search(axios),
  catalog: catalog(axios),
  product: product(axios),
  profile: profile(axios),
  checkout: checkout(axios),
  auth: auth(axios),
  geolocation: geolocation(axios),
  general: general(axios),
  giftcard: giftcard(axios),
  services: services(axios),
  favorites: favorites(axios),
  reviews: reviews(axios),
  brands: brands(axios),
  kompra: kompra(axios),
  emex: emex(axios, xml),
});

export default ({ $axios }, inject) => {
  const api = apiFactory($axios);
  inject('api', api);
};
