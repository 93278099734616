var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"alert",class:{
      'alert--centered': _vm.centered,
      'alert--error': _vm.type === 'error',
      'alert--success': _vm.type === 'success',
      'alert--card': _vm.icon === 'card',
      'alert--lg': _vm.lg,
      'alert--sm': _vm.sm,
      'alert--md': _vm.md,
    }},[(_vm.$slots.title)?_c('p',{staticClass:"alert__text alert__title"},[_vm._t("title")],2):_vm._e(),_vm._v(" "),(_vm.$slots.content)?_c('div',{staticClass:"alert__text alert__content font font_m"},[_vm._t("content")],2):_vm._e(),_vm._v(" "),(_vm.closable)?_c('button',{staticClass:"alert__text alert__close",attrs:{"aria-label":"Закрыть уведомление"},on:{"click":function($event){return _vm.close()}}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Закрыть уведомление")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }