<template>
  <button
    type="button"
    class="menu-toggle"
    :class="{
      'menu-toggle--opened': value,
      'menu-toggle--not-hide-text': hideTextOnStart
    }"
    @click="handleGoToCatalog"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <span class="menu-toggle__icon">
      <span></span>
    </span>

    <span class="menu-toggle__text font_m">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'MenuToggle',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hideTextOnStart: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'mobileXs';
    },
  },
  methods: {
    closeMenu() {
      this.$emit('input', false);
    },
    handleGoToCatalog() {
      if (!this.mqIsMobile) {
        this.$router.push('/catalog');
      } else {
        this.$emit('changeMobileMenu');
      }
    },
    handleMouseEnter() {
      if (!this.mqIsMobile) {
        this.$emit('mouseenter');
      }
    },
    handleMouseLeave() {
      if (!this.mqIsMobile) {
        this.$emit('mouseleave');
      }
    },
    handleOpenMenu() {
      // this.$emit('input', !this.value);
      this.$emit('input', true);
    },
  },
}
</script>

