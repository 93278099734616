<template>
  <div class="menu__catalog">
    <div class="menu__catalog-content">
      <div class="container">
        <ul class="menu__catalog-list">
          <li
            v-for="category in allCategories"
            :key="category.id"
            class="menu__catalog-item"
          >
            <nuxt-link
              class="menu__catalog-category-title font font_m font_uppercase font_bold font_underline"
              :to="{
                name: 'catalog.category',
                params: { category: category.code }
              }"
              @click.native="onLinkClick"
            >
              {{ category.name }}
            </nuxt-link>

            <ul
              v-if="category.items && category.items.length > 0"
              class="menu__catalog-subitems"
            >
              <li
                v-for="subcategory in category.items"
                :key="subcategory.id"
                class="menu__catalog-subitem"
              >
                <nuxt-link
                  class="menu__catalog-subcategory-link font font_m"
                  :to="{
                    name: 'catalog.category',
                    params: { category: subcategory.code }
                  }"
                  @click.native="onLinkClick"
                >
                  {{ subcategory.name }}
                </nuxt-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <button
      class="menu__overlay"
      type="button"
      aria-label="Закрыть меню"
      @click="onLinkClick"
    ></button>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'HeaderBottomCatalog',
  computed: {
    ...mapState('catalog', ['allCategories']),
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
  },
}
</script>

