<template>
  <div
    class="input4digit"
    :class="{
      error: showError,
    }"
    @paste.prevent="handlePaste"
  >
    <input
      ref="input1"
      v-model="input1"
      class="input4digit__input"
      type="number"
      autofocus
      autocomplete="one-time-code"
      @input="handleInput1Change"
      @keyup="handleInput1Keyup"
      @focus="handleInput1Focus"
    />
    <input
      ref="input2"
      v-model="input2"
      class="input4digit__input"
      type="number"
      @input="handleInput2Change"
      @keyup="handleInput2Keyup"
      @keydown="handleInput2Keydown"
      @focus="handleInput2Focus"
    />
    <input
      ref="input3"
      v-model="input3"
      class="input4digit__input"
      type="number"
      @input="handleInput3Change"
      @keyup="handleInput3Keyup"
      @keydown="handleInput3Keydown"
      @focus="handleInput3Focus"
    />
    <input
      ref="input4"
      v-model="input4"
      class="input4digit__input"
      type="number"
      @input="handleInput4Change"
      @keyup="handleInput4Keyup"
      @keydown="handleInput4Keydown"
      @focus="handleInput4Focus"
    />
  </div>
</template>
<script>
export default {
  name: 'Input4Digit',
  prop: ['hidden'],
  model: {
    prop: 'hidden',
    event: 'blur',
  },
  props: {
    showError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
    }
  },
  mounted() {
  },
  beforeMount() {
  },
  created() {
  },
  methods: {
    handleInput1Change(e) {
      if (this.input1.length === 1) {
        this.$refs.input2.focus();
      } else if (this.input1.length > 1) {
        this.input1 = this.input1[0];
        this.$refs.input2.focus();
      } else if (this.input1.length === 0) {
        this.input2 = '';
        this.input3 = '';
        this.input4 = '';
      }
      this.$emit('blur', `${this.input1}${this.input2}${this.input3}${this.input4}`);
    },
    handleInput1Keyup(e) {
      if (e.key === 'e' || e.key === ',' || e.key === '.') {
        this.$refs.input1.value = '';
      }
    },
    handleInput2Change(e) {
      if (this.input2.length === 1) {
        this.$refs.input3.focus();
      } else if (this.input2.length > 1) {
        this.input2 = this.input2[0];
        this.$refs.input3.focus();
      } else if (this.input2.length === 0) {
        // this.$refs.input1.focus();
        this.input3 = '';
        this.input4 = '';
      }
      this.$emit('blur', `${this.input1}${this.input2}${this.input3}${this.input4}`);
    },
    handleInput2Keyup(e) {
      if (e.key === 'e' || e.key === ',' || e.key === '.') {
        this.$refs.input2.value = '';
      }
    },
    handleInput2Keydown(e) {
      if (e.key === 'Backspace' && this.input2 === '') {
        this.$refs.input1.focus();
      }
    },
    handleInput3Change(e) {
      if (this.input3.length === 1) {
        this.$refs.input4.focus();
      } else if (this.input3.length > 1) {
        this.input3 = this.input3[0];
        this.$refs.input4.focus();
      } else if (this.input3.length === 0) {
        // this.$refs.input2.focus();
        this.input4 = '';
      }
      this.$emit('blur', `${this.input1}${this.input2}${this.input3}${this.input4}`);
    },
    handleInput3Keyup(e) {
      if (e.key === 'e' || e.key === ',' || e.key === '.') {
        this.$refs.input3.value = '';
      }
    },
    handleInput3Keydown(e) {
      if (e.key === 'Backspace' && this.input3 === '') {
        this.$refs.input2.focus();
      }
    },
    handleInput4Change(e) {
      if (this.input4.length > 1) {
        this.input4 = this.input4[0];
      } else if (this.input4.length === 0) {
        // this.$refs.input3.focus();
      }
      this.$emit('blur', `${this.input1}${this.input2}${this.input3}${this.input4}`);
    },
    handleInput4Keyup(e) {
      if (e.key === 'e' || e.key === ',' || e.key === '.') {
        this.$refs.input4.value = '';
      }
    },
    handleInput4Keydown(e) {
      if (e.key === 'Backspace' && this.input4 === '') {
        this.$refs.input3.focus();
      }
    },
    handleInput1Focus() {
    },
    handleInput2Focus() {
      if (this.input1.length === 0) {
        this.$refs.input1.focus();
      }
    },
    handleInput3Focus() {
      if (this.input1.length === 0) {
        this.$refs.input1.focus();
      } else if (this.input2.length === 0) {
        this.$refs.input2.focus();
      }
    },
    handleInput4Focus() {
      if (this.input1.length === 0) {
        this.$refs.input1.focus();
      } else if (this.input2.length === 0) {
        this.$refs.input2.focus();
      } else if (this.input3.length === 0) {
        this.$refs.input3.focus();
      }
    },
    handlePaste(e) {
      const first = e.clipboardData.getData('text').slice(0, 4);
      if (first[0]) {
        this.input1 = first[0];
      }
      if (first[1]) {
        this.input2 = first[1];
      }
      if (first[2]) {
        this.input3 = first[2];
      }
      if (first[3]) {
        this.input4 = first[3];
      }
      this.$emit('blur', first)
    },
  },
}
</script>
