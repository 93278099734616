<template>
  <div
    class="custom-select"
    :class="{
      'custom-select--numeric': isNumeric,
      'custom-select--float': float,
      'custom-select--active': $attrs.value,
      'custom-select--white': styleLight,
      'custom-select--invalid': error,
      'custom-select--sm': small,
    }"
  >
    <label v-if="$slots.default" class="custom-select__label" :for="selectId">
      <slot />
    </label>
    <p v-if="error" class="custom-select__message" role="alert">
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </p>
    <client-only>
      <v-select
        :id="selectId"
        v-model="$attrs.value"
        :class="className"
        :options="options"
        :label="labelOptionName"
        :disabled="disabled"
        :clearable="false"
        :searchable="false"
        :append-icon="appendIcon"
        :placeholder="placeholder"
        :style="{
          '--customSelectLeftPadding': prependIcon?.width ? `${prependIcon.width + 16}px` : '16px',
        }"
        :calculate-position="withPopper"
        @input="$emit('input', $attrs.value)"
      >
        <div
          slot="no-options"
          :style="{
            paddingTop: '16px',
            paddingBottom: '16px',
          }"
          @click="$refs.select.open = false"
        >
          {{ noOptionsText }}
        </div>
        <template #open-indicator="{ attributes }">
          <component :is="customIcon" v-bind="attributes" />
        </template>
      </v-select>
      <svg-icon
        v-if="prependIcon"
        :name="prependIcon.icon"
        :width="prependIcon.width"
        :height="prependIcon.height"
        class="prependIcon"
      />
    </client-only>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import {getRandomIntInclusive} from '~/plugins/generators';
import CustomSelectDefaultIcon from '~/components/elements/CustomSelectDefaultIcon.vue';

export default {
  name: 'CustomSelect',
  components: {
    CustomSelectDefaultIcon,
  },
  inheritAttrs: false,
  props: {
    labelOptionName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
    sizeSm: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: Object,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    customIcon: {
      type: [String, Object],
      default: () => {
        return CustomSelectDefaultIcon;
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    noOptionsText: {
      type: String,
      default: 'Список пуст',
    },
  },
  data() {
    return {
      id: getRandomIntInclusive(1, 100000),
    };
  },
  computed: {
    CustomSelectDefaultIcon() {
      return CustomSelectDefaultIcon
    },
    selectId() {
      return `v-select-${this.id}`;
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
};
</script>
<style scoped>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
