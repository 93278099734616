export default axios => ({
  fetchProduct(payload) {
    try {
      return axios
        .$get('/v1/catalog/product-detail', {
          params: payload,
        });
    } catch (error) {
      if (error.response.status === 404) {
        throw error;
      }
      throw new Error(`API Request Error (fetchProduct): ${error}`);
    }
  },
  fetchProductDeliveries(payload) {
    try {
      return axios
        .$get('/v1/catalog/product-detail/deliveries', {
          params: payload,
        });
    } catch (error) {
      throw new Error(`API Request Error (fetchProductDeliveries): ${error}`);
    }
  },
  fetchProductOptions(groupId) {
    try {
      return axios
        .$get('/v1/catalog/variants', {
          params: {
            groupId,
          },
        });
    } catch (error) {
      throw new Error(`API Request Error (fetchProductOptions): ${error}`);
    }
  },
  fetchProductBundles(payload) {
    try {
      return axios.$get('/v1/catalog/bundles', {
        params: payload,
      }).then(response => response.bundles);
    } catch (error) {
      throw new Error(`API Request Error (fetchProductBundles): ${error}`);
    }
  },
  async fetchProductSimilar({ category, brand }) {
    try {
      const response = await axios.$get('/v1/catalog/products', {
        params: {
          'filter[category]': category,
          'filter[brand][0]': brand,
          page: 1,
          limit: 12,
        },
      })

      return response.items
    } catch (error) {
      throw new Error(`API Request Error (fetchProductSimilar): ${error}`);
    }
  },
});
