import {mapGetters, mapState} from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      currentCompanyId: 'auth/currentCompanyId',
    }),
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      if (oldValue && this.hasSession) {
        const name = this.$route.name

        if (name === 'checkout.products' || name === 'checkout.services') {
          this.$router.go(-1)
          return
        }

        if (name.includes('profile')) {
          window.location = window.location.origin + '/profile/'
          return;
        }

        this.$router.go(0)
      }
    },
  },
};
