<template>
  <div class="search header__search">
    <LocationIconButton />
    <SearchIconButton />
    <CustomPopup
      v-if="isPopupSearch"
      class="search__popup"
      :pop-up-state="isOpenedSearch"
      @closePopUp="setIsOpenedSearch(false)"
    >
      <SearchInput />
      <SearchResult
        :suggestions="getSearchSuggestions"
        @close="clearSearchResult(true)"
      >
        <SearchResultProducts
          v-if="getSearchProducts.length"
          :products="getSearchProducts"
          @close="clearSearchResult(true)"
        />
        <SearchResultEmpty v-else-if="isSearchEmpty" :query="searchQuery" />
      </SearchResult>
    </CustomPopup>
    <CustomModal
      v-else-if="mqIsMobile"
      v-show="isOpenedSearch"
      title="Поиск"
      class="search__modal"
      @close="setIsOpenedSearch"
      @closeOverlay="setIsOpenedSearch"
    >
      <template #content>
        <SearchInput />
        <SearchResult
          v-scroll-lock="isOpenedSearch"
          :suggestions="getSearchSuggestions"
          @close="clearSearchResult(true)"
        >
          <SearchResultProducts
            v-if="getSearchProducts.length"
            :products="getSearchProducts"
            @close="clearSearchResult(true)"
          />
          <SearchResultEmpty v-else-if="isSearchEmpty" :query="searchQuery" />
        </SearchResult>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import SearchInput from '~/layouts/components/Search/Input';
import CustomPopup from '~/components/elements/CustomPopup';
import SearchResult from '~/layouts/components/Search/Result';
import SearchResultProducts from '~/layouts/components/Search/Result/Products';
import CustomModal from '~/components/elements/CustomModal';
import SearchResultEmpty from '~/layouts/components/Search/Result/Empty';
import SearchIconButton from '~/layouts/components/Search/IconButton.vue';
import LocationIconButton from '~/layouts/components/LocationModal/IconButton.vue';

export default {
  name: 'HeaderMainSearch',
  components: {
    SearchIconButton,
    LocationIconButton,
    SearchResultEmpty, CustomModal, SearchResultProducts, SearchResult, CustomPopup, SearchInput},
  props: {
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('search', ['searchQuery', 'isOpenedSearch', 'isSearchPending']),
    ...mapGetters({
      getSearchSuggestions: 'search/getSearchSuggestions',
      getSearchProducts: 'search/getSearchProducts',
    }),
    mqIsTablet() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    isPopupSearch() {
      if (this.mqIsMobile) return false

      return !this.isScrollOffset || this.mqIsTablet
    },
    isSearchEmpty() {
      return this.searchQuery.length > 2 && !this.isSearchPending
    },
  },
  watch: {
    '$route'() {
      if (this.isOpenedSearch) {
        this.clearSearchResult(true)
      }
    },
    isPopupSearch(value) {
      if (value && !this.searchQuery && this.isOpenedSearch) {
        this.clearSearchResult(true)
      }
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch', 'setSearchQuery', 'clearSearchResult' ]),
  },
}
</script>
