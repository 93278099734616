<template>
  <div v-show="!isCookiesAccepted" class="cookies-wrapper">
    <div class="cookies">
      <div class="cookies__body">
        <div class="font">
          {{ cookieText }} Посещая страницы сайта, вы соглашаетесь с
          <a
            href="/privacy-policy"
            class="cookies__link"
            target="_blank"
            rel="noreferrer noopener"
          >
            Политикой конфиденциальности
          </a>
        </div>
        <Button
          theme="link"
          class="cookies__accept"
          @click="acceptCookiesHandler"
        >
          <svg-icon name="close" width="16" height="14" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '~/components/elements/Button';
import {cookieNames} from '~/plugins/enums/cookie';
import {MAX_AGE_COOKIE_AGREE} from '~/plugins/config';

export default {
  components: {Button},
  data() {
    return {
      isCookiesAccepted: 1,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'mobileXs';
    },
    cookieText() {
      if (this.mqIsMobile) {
        return 'Мы собираем куки.'
      }

      return 'На сайте используются файлы cookies, которые его делают более удобным для каждого пользователя.'
    },
  },
  mounted() {
    this.isCookiesAccepted = this.getCookieAgree() || 0
    this.setCookieAgree(this.isCookiesAccepted)
  },
  methods: {
    setCookieAgree(agree) {
      this.$cookies.set(cookieNames.AGREE_COOKIE, JSON.stringify(agree), {
        maxAge: MAX_AGE_COOKIE_AGREE,
        path: '/',
      });
    },
    getCookieAgree() {
      return +this.$cookies.get(cookieNames.AGREE_COOKIE);
    },
    acceptCookiesHandler(event) {
      if (!event.target?.dataset?.more) {
        this.isCookiesAccepted = 1
        this.setCookieAgree(this.isCookiesAccepted)
      }
    },
  },
}
</script>
