<template>
  <CustomImagePicture
    :class="{ '_ibg' : isBg }"
    :style="{paddingTop: aspectRatio}"
    :media="mediaImage"
    :webp="webp"
    :src="src"
    :alt="alt"
    :loading="loading"
    @load="$emit('load')"
  />
</template>

<script>
import { getImageAllFormats } from '~/plugins/helpers/file';
import CustomImagePicture from '~/components/elements/CustomImage/Picture';

export default {
  name: 'CustomImage',
  components: {CustomImagePicture},
  props: {
    isBg: {
      type: Boolean,
      default: true,
    },
    imageId: {
      type: [Number, String],
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: String,
      default: '100%',
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    ext: {
      type: String,
      default: 'jpg',
    },
    width: {
      type: [Number, String],
      default: '',
    },
    height: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    image() {
      return this.getImage(this.imageId, this.ext, this.width, this.height, this.imageSrc)
    },
    src() {
      return this.image[this.ext]
    },
    webp() {
      return this.image.webp
    },
    mediaImage() {
      if (!this.media) return null

      return this.media.map(item => {
        const image = this.getImage(item.id, this.ext, item.width, item.height)

        return {
          image: image[this.ext],
          media: item.media,
        }
      })
    },
  },
  methods: {
    getImageAllFormats,
    getImage(id, ext, width = '', height = '', src = '') {
      return this.getImageAllFormats(id, width, height, ext, src)
    },
  },
}
</script>
