<template>
  <div class="main-sidebar-wrapper" :class="{ 'main-sidebar-wrapper--closed': !isOpenedSidebar }">
    <transition name="fade">
      <div v-show="isOpenedSidebar" class="main-sidebar-wrapper__background" @click.self="closeSidebar">
        <span class="visually-hidden">Закрыть меню</span>
      </div>
    </transition>
    <Sidebar v-show="isOpenedSidebar" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Sidebar from '@/layouts/components/Sidebar';

import './index.css';

export default {
  name: 'SidebarWrapper',
  components: {
    Sidebar,
  },
  computed: {
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
  },
  methods: {
    ...mapActions({ setIsOpenedSidebar: 'setIsOpenedSidebar' }),
    closeSidebar() {
      this.setIsOpenedSidebar(false);
    },
  },
};
</script>
