<template>
  <CustomModal
    v-show="visibleLocationModal"
    id="location-modal"
    class="location-modal"
    :empty="mqIsMobile"
    :visible="visibleLocationModal"
    title="Выберите город"
    @close="closeModal"
    @closeOverlay="closeModal"
  >
    <template #content>
      <LocationSelection @close="closeModal" />
    </template>
  </CustomModal>
</template>

<script>
import './index.css';
import { mapMutations, mapState } from 'vuex';
import CustomModal from '@/components/elements/CustomModal';
import LocationSelection from '@/components/auth/LocationSelection';

export default {
  name: 'LocationModal',
  components: {
    CustomModal, LocationSelection,
  },
  data() {
    return {
      scrollLock: false,
    }
  },
  computed: {
    ...mapState('geolocation', [
      'visibleLocationModal',
    ]),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
  },
  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    closeModal() {
      this.setVisibleLocationModal(false);
    },
  },
};
</script>
