<template>
  <CustomPopup
    v-if="!mqIsMobile"
    class="search__popup search__popup-bottom"
    :pop-up-state="isOpenedSearch"
    @closePopUp="setIsOpenedSearch(false)"
  >
    <SearchInput />
    <SearchResult
      :suggestions="getSearchSuggestions"
      @close="clearSearchResult(true)"
    >
      <SearchResultProducts
        v-if="getSearchProducts.length"
        :products="getSearchProducts"
        @close="clearSearchResult(true)"
      />
      <SearchResultEmpty v-else-if="isSearchEmpty" :query="searchQuery" />
    </SearchResult>
  </CustomPopup>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import SearchInput from '~/layouts/components/Search/Input';
import CustomPopup from '~/components/elements/CustomPopup';
import SearchResult from '~/layouts/components/Search/Result';
import SearchResultProducts from '~/layouts/components/Search/Result/Products';
import SearchResultEmpty from '~/layouts/components/Search/Result/Empty';

export default {
  name: 'HeaderBottomSearch',
  components: {SearchResultEmpty, SearchResultProducts, SearchResult, CustomPopup, SearchInput},
  computed: {
    ...mapState('search', ['searchQuery', 'isOpenedSearch', 'isSearchPending']),
    ...mapGetters({
      getSearchSuggestions: 'search/getSearchSuggestions',
      getSearchProducts: 'search/getSearchProducts',
    }),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isSearchEmpty() {
      return this.searchQuery.length > 2 && !this.isSearchPending
    },
  },
  watch: {
    '$route'() {
      if (this.isOpenedSearch) {
        this.onCloseHandler()
      }
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch', 'setSearchQuery', 'clearSearchResult' ]),
  },
}
</script>
