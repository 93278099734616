<template>
  <client-only>
    <StarRating
      v-model="rating"
      :show-rating="false"
      :star-size="size"
      :read-only="disabled"
      :increment="starHalf ? 0.5 : 1"
      :active-color="activeColor"
    />
  </client-only>
</template>

<script>
export default {
  name: 'CustomRate',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    starHalf: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: '#12121d',
    },
    size: {
      type: Number,
      default: 14,
    },
  },
  computed: {
    rating: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
