<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_center font_m font_bold">
          Забыли пароль?
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon name="close" width="15" height="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <div class="font font_sm font_center sign-up_message">
        Введите номер телефона привязанный<br />
        к вашему аккаунту
      </div>
      <form class="sign-up__form" @submit.prevent="handleResetPassword">
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Номер телефона
          </div>
          <InputTel
            key="login-phone"
            v-model="phoneNumber"
            class="sign-up__form-input"
            placeholder="Номер телефона"
            data-test="phone"
            @onValidate="onValidateHandler"
          />
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isSocialAuth"
            size="xl"
            full
            type="submit"
            data-test="get-code"
            class="font_no-transform"
            :disabled="isDisabledResetPassword"
          >
            Восстановить пароль
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {v4} from 'uuid';
import {mapActions, mapMutations, mapState} from 'vuex';
import env from '@/.env.json';
// import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
import Button from '@/components/elements/Button';
import InputTel from '~/components/elements/InputTel';
import { cookieNames } from '~/plugins/enums/cookie';
import { MAX_AGE_API_SESSION, MAX_AGE_COOKIE_AGREE } from '~/plugins/config';

export default {
  name: 'AuthModalSignInForgotPassword',
  components: {
    InputTel,
    Button,
  },
  data() {
    return {
      authModalPages,
      password: '',
      isDisabledGetCodeBtn: false,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
      isDisabledResetPassword: false,
      phoneNumber: '',
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    // phoneError() {
    //   let text = '';
    //
    //   if (!this.validatedPhone.isValid) text = validationMessages.ERROR_PHONE_INPUT;
    //
    //   return text;
    // },
    // isDisabledNextButton() {
    //   return this.phoneError.length;
    // },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms', 'resetPassword', 'resetPasswordSendSms']),
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    // async goToSendingSms() {
    //   try {
    //     this.isDisabledGetCodeBtn = true;
    //     const data = {
    //       phone: this.validatedPhone.number,
    //     };
    //     const { isNew } = await this.loginByPhoneSendSms(data);
    //     this.setNewUser(isNew)
    //     this.setAcceptedPhone(this.validatedPhone.international);
    //     this.setCurrentAuthModalPage(authModalPages.ENTER_PASSWORD);
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     this.isDisabledGetCodeBtn = false;
    //   }
    // },
    // async onSubmitPhoneNumber() {
    //   try {
    //     this.isDisabledGetCodeBtn = true;
    //     const data = {
    //       phone: this.validatedPhone.number,
    //     };
    //     const { isNew } = await this.loginByPhoneSendSms(data);
    //     this.setNewUser(isNew)
    //     this.setAcceptedPhone(this.validatedPhone.international);
    //     this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD_CODE);
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     this.isDisabledGetCodeBtn = false;
    //   }
    // },
    handleForgotPassword() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
    async handleResetPassword(payload) {
      try {
        this.isDisabledResetPassword = true;
        const data = {
          phone: this.validatedPhone.number,
        }
        const apimsSession = v4();
        this.$cookies.set(cookieNames.API_SESSION, apimsSession, {
          maxAge: MAX_AGE_API_SESSION,
          path: '/',
        });
        localStorage.setItem(cookieNames.API_SESSION, apimsSession);
        await this.resetPasswordSendSms(data);
        this.setAcceptedPhone(this.validatedPhone.number);
        this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD_CODE);
      } catch(error) {
        console.log(error)
      } finally {
        this.isDisabledResetPassword = false;
      }
    },
  },
};
</script>
