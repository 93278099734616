<template>
  <div class="header-top">
    <div class="container header-top__container">
      <div class="header-top__city">
        <button
          type="button"
          class="header-top__link
              header-top__link--toggle
              header-top__link--ico
              header-top__link--ico-map
              font font_m"
          @click="openLocationModal"
        >
          {{ getSelectedCity }}
        </button>
      </div>

      <HeaderTopLinks :links="links" />
      <HeaderTopHelp />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import HeaderTopLinks from '~/layouts/components/Header/Top/HeaderTopLinks';
import HeaderTopHelp from '~/layouts/components/Header/Top/HeaderTopHelp';

export default {
  name: 'HeaderTop',
  components: {HeaderTopHelp, HeaderTopLinks},
  data() {
    return {
      links: [
        {
          name: 'Доставка по всему Казахстану',
          to: '/payment_delivery',
          icon: 'delivery',
        },
        {
          name: 'Тысячи товаров от официальных поставщиков',
          to: { name: 'catalog' },
          icon: 'products',
        },
        {
          name: 'Бонусы за покупки',
          to: '/bonuses',
          icon: 'bonuses',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
  },
  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    openLocationModal() {
      this.setVisibleLocationModal(true);
    },
  },
}
</script>
