<template>
  <nav class="main-nav">
    <ul class="main-nav__menu">
      <li class="main-nav__menu-group">
        <ul class="main-nav__menu-primary-wrapper">
          <li
            v-for="(menuItem, menuIdx) in mainItems"
            :key="`m-${menuIdx}`"
            class="main-nav__item main-nav__item--primary"
            :class="{
              'main-nav__item--current': isCurrentRoute(menuItem.url)
            }"
          >
            <a
              v-if="isCurrentRoute(menuItem.url)"
              class="main-nav__link"
            >
              {{ menuItem.name }}
            </a>

            <nuxt-link
              v-else
              :to="menuItem.url"
              class="main-nav__link"
            >
              {{ menuItem.name }}
            </nuxt-link>
          </li>
        </ul>
      </li>

      <li
        v-for="(menuItem, menuIdx) in secondaryItems"
        :key="`s-${menuIdx}`"
        class="main-nav__item main-nav__item--secondary"
        :class="{
          'main-nav__item--current': isCurrentRoute(menuItem.url)
        }"
      >
        <a
          v-if="isCurrentRoute(menuItem.url)"
          class="main-nav__link"
        >
          {{ menuItem.name }}
        </a>

        <nuxt-link
          v-else
          :to="menuItem.url"
          class="main-nav__link"
        >
          {{ menuItem.name }}
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import './index.css';

import { mapMutations } from 'vuex';

export default {
  name: 'LayoutMenu',

  components: {},

  props: {
    mainItems: {
      type: Array,
      required: true,
    },

    secondaryItems: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapMutations('catalog', ['SET_CURRENT_CATEGORY', 'SET_CURRENT_ITEMS_FILTERS']),
  },

  methods: {
    isCurrentRoute (url) {
      const isCatalogUrl = url === '/catalog';

      return url === this.$route.path
        || isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product;
    },
  },
};
</script>
