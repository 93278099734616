<template>
  <nuxt-link
    class="logo-wrapper"
    :to="{ name: 'main' }"
    :style="logoImage.style"
    itemscope
    itemtype="https://schema.org/Organization"
  >
    <div class="logo-wrapper__body">
      <div class="logo">
        <div class="logo__img">
          <img :src="logoImage.src" itemprop="logo" alt="logotype" />
        </div>
      </div>
      <div v-if="isText && logoText" class="logo__text font font_xs font_center">
        {{ logoText }}
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import {mapState} from 'vuex';
import {generateFileOriginalPath} from '~/plugins/helpers/file';

export default {
  name: 'Logo',
  props: {
    customLogo: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({ logo: 'logo' }),
    isMainPage() {
      return this.$route.name === 'main';
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    logoPC() {
      return this.logo?.pc
    },
    logoMobile() {
      return this.logo?.mobile
    },
    currentLogo() {
      return (this.mqIsMobile || this.small) && this.logoMobile ? this.logoMobile : this.logoPC
    },
    logoText() {
      return this.currentLogo?.text
    },
    logoImage() {
      return {
        src: this.customLogo || this.generateFileOriginalPath(this.currentLogo?.id),
      }
    },
  },
  methods: {
    generateFileOriginalPath,
  },
}
</script>
