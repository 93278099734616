<template>
  <span class="required-field">*</span>
</template>
<script>
export default {
  name: 'RequiredField',
}
</script>
<style>
.required-field {
  color: #F83446;
}
</style>
