<template>
  <div class="location">
    <div v-if="!viewLocationList" class="location__current">
      <div class="container--modal location__container">
        <div class="location__header">
          <div class="location__text">
            Информация о доставке будет отображаться для региона
          </div>
          <div class="location__city">
            {{ getSelectedCity }}
          </div>
        </div>
        <div class="location__body">
          <div class="location__point">
            <span class="location__point-ico" />
          </div>
        </div>
        <div class="location__actions">
          <Button
            class="location__action"
            data-test="city-true"
            @click="confirmCity(getSelectedCity)"
          >
            Да, все верно
          </Button>
          <button class="location__button location__action" type="button" data-test="city-false" @click="changeCity()">
            Нет, другой город
          </button>
        </div>
      </div>
    </div>
    <div v-else class="location__selection">
      <LocationSelection @close="closeList()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import * as amplitude from '@amplitude/analytics-browser';
import './index.css';
import LocationSelection from '@/components/auth/LocationSelection';
import Button from '@/components/elements/Button';

export default {
  name: 'Location',
  components: {
    LocationSelection,
    Button,
  },
  data() {
    return {
      viewLocationList: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
  },
  methods: {
    ...mapActions('geolocation', ['setIsConfirmedCity']),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    confirmCity(city) {
      const identify = new amplitude.Identify().set('user_city', city);
      amplitude.identify(identify);
      this.setIsConfirmedCity(true);
      this.$emit('closeModal');
    },
    changeCity() {
      this.viewLocationList = true;
    },
    closeList() {
      this.viewLocationList = false;
    },
  },
};
</script>
