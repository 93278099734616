<template>
  <div class="company-search-item" @click="onClickHandler">
    <div v-if="item.name" class="company-search-item__name">
      {{ item.name }}
    </div>
    <div v-if="item.kpp" class="company-search-item__kpp">
      КПП: {{ item.kpp }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanySearchItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit('onClick', this.item)
    },
  },
}
</script>

