<template>
  <div class="support">
    <div v-if="supportPhone || supportEmail" class="support__content">
      <p v-if="supportTime" class="support__time font font_m font_grey">
        {{ supportTime }}
      </p>

      <a
        v-if="supportPhone"
        :href="`tel:${supportPhone}`"
        class="support__phone font font_primary font_bold font_title-l"
      >
        {{ phone }}
      </a>

      <!-- <ul class="support__social">
        <li class="support__social-item">
          <Button
            href="https://vk.me/#"
            target="_blank"
            class="support__social-link"
            aria-label="Связаться с нами в VK"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>

        <li class="support__social-item">
          <Button
            href="tg://resolve?domain=#"
            target="_blank"
            class="support__social-link"
            aria-label="Связаться с нами в Telegram"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>

        <li class="support__social-item">
          <Button
            href="#"
            target="_blank"
            class="support__social-link"
            aria-label="Связаться с нами в чате"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>
      </ul> -->

      <template v-if="supportEmail">
        <div class="support__item-wrapper">
          <p class="support__label">
            E-mail:
          </p>
          <a :href="`mailto:${supportEmail}`" class="support__mail font font_m font_medium font_primary">
            {{ supportEmail }}
          </a>
        </div>
      </template>

      <p v-if="supportEmail" class="support__time font font_m font_grey">
        {{ supportText }}
      </p>
    </div>

    <ul v-if="menu.length" class="support__links">
      <li
        v-for="(link, idx) in menu"
        :key="`menu${idx}`"
        class="support__links-item"
      >
        <nuxt-link
          class="support__link font font_m font_underscore"
          :to="link.url"
        >
          {{ link.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import env from '@/.env.json';
import {phoneFormat} from '~/plugins/formatting';
import './index.css';

export default {
  name: 'HeaderSupport',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      env,
    }
  },
  computed: {
    ...mapGetters({
      getMenuHelpAdditional: 'getMenuHelpAdditional',
    }),
    supportPhone() {
      const phone = this.getMenuHelpAdditional?.phone || ''
      return phone.replace(/[^\d+]/g, '')
    },
    supportEmail() {
      return this.getMenuHelpAdditional?.email || ''
    },
    supportTime() {
      return this.getMenuHelpAdditional?.schedule || ''
    },
    supportText() {
      return this.getMenuHelpAdditional?.text || ''
    },
    phone() {
      return this.phoneFormat(this.supportPhone);
    },
  },
  methods: {
    phoneFormat,
  },
}
</script>
