<template>
  <div class="search-empty">
    <div class="search-empty__body">
      По запросу <span class="highlighted">«{{ query }}»</span>
      <div class="search-empty__body-text">
        ничего не найдено
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResultEmpty',
  props: {
    query: {
      type: String,
      default: '',
    },
  },
}
</script>

