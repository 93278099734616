<template>
  <div class="not-found">
    <h3 class="not-found__title">
      К сожалению, запрашиваемая вами страница не найдена.
      Возможно, она переехала или&nbsp;удалена.
    </h3>
    <p class="not-found__text">
      Проверьте правильность введенного адреса в&nbsp;адресной строке или вернитесь на главную
    </p>
    <Button to="/" class="not-found__button">
      Вернуться на главную
    </Button>
  </div>
</template>

<script>
import Button from '@/components/elements/Button';

export default {
  name: 'NotFound',
  components: {
    Button,
  },
}
</script>
