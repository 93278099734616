<template>
  <ul class="search-result__products search-products">
    <li v-for="(item, index) in products" :key="index">
      <NuxtLink
        :to="{
          name: 'catalog.category.product',
          params: {
            category: item.categoryCodes[item.categoryCodes.length - 1],
            product: item.code,
          }
        }"
        @click.native="onClickHandler"
      >
        <SearchResultProductsItem :item="item" />
      </NuxtLink>
    </li>
  </ul>
</template>

<script>

import SearchResultProductsItem from '~/layouts/components/Search/Result/Products/Item';
export default {
  name: 'SearchResultProducts',
  components: {SearchResultProductsItem},
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClickHandler(item) {
      this.$emit('close')
    },
  },
}
</script>
