<template>
  <div class="search__form">
    <button
      class="search__close"
      aria-label="Очистить"
      @click="closeSearch"
    >
      <span class="visually-hidden">Закрыть</span>
    </button>
    <input
      ref="searchInput"
      v-model="searchString"
      class="search__input"
      :class="{'search__input--active': searchString.length > 0}"
      type="text"
      placeholder=""
      @focus="onFocusHandler"
      @keyup.enter="onKeyEnter"
    >
    <!--    <button-->
    <!--      v-if="searchString"-->
    <!--      class="search__clear"-->
    <!--      aria-label="Очистить"-->
    <!--      @click="clearSearch"-->
    <!--    >-->
    <!--      <span class="visually-hidden">Очистить</span>-->
    <!--    </button>-->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'SearchInput',
  computed: {
    ...mapState('search', [ 'searchQuery', 'isOpenedSearch']),
    searchString: {
      get() {
        return this.searchQuery
      },
      set(newValue) {
        this.setSearchQuery(newValue)
      },
    },
  },
  watch: {
    isOpenedSearch(value) {
      if (value) {
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
  methods: {
    ...mapActions('search', ['onInputFocus', 'setSearchQuery', 'clearSearchResult', 'setIsOpenedSearch']),
    clearSearch() {
      this.clearSearchResult()
    },
    closeSearch() {
      this.setIsOpenedSearch(false);
    },
    onFocusHandler() {
      this.onInputFocus()
      this.$emit('onFocus')
      this.setIsOpenedSearch(true)
    },
    onKeyEnter() {
      this.$router.push({
        name: 'search',
        query: { search_string: this.searchString },
      });
      this.$emit('onEnter')
    },
  },
}
</script>
<style scoped>
.search__label {
  cursor: pointer;
}
</style>
