export const actions = {
  async getEmexGuid({ dispatch }, payload) {
    const suggestion = await dispatch('getEmexSuggestion', payload)

    return {
      cityGuid: `${suggestion?.data?.code}` || null,
      regionGuid: `${suggestion?.data?.city?.code}` || null,
    }
  },

  async getEmexSuggestion(_, { region, city }) {
    const searchData = {
      conditions: {
        namecontainsparts: `${city.name} ${city.type}`,
        country: 'KZ',
      },
    }

    if (region?.name) {
      searchData.conditions.city = `${region.name} ${region.type}`
    }

    const [ suggestion ] = await this.$api.emex.citySuggestion(searchData, 1)

    return suggestion
  },
}
