function validateSequence(numbers) {
  const standartSequence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const alternativeSequence = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
  let controlDigit;

  if (numbers.length !== 12) {
    return false;
  }

  controlDigit =
    standartSequence.reduce(
      (accumulator, currentValue, index) =>
    accumulator + currentValue * numbers[index]) % 11;
  if (controlDigit === 10) {
    controlDigit =
      alternativeSequence.reduce(
        (accumulator, currentValue, index) =>
      accumulator + currentValue * numbers[index]) % 11;
  }

  return controlDigit < 10;
}

function inRange(needle, min, max) {
  return needle >= min && needle <= max;
}

function getNumberList(numbers) {
  return numbers.split('').map((n) => parseInt(n, 10));
}

function isIIN(typeNumber) {
  if (!inRange(typeNumber, 0, 6)) {
    throw new Error('invalid identification number');
  }
  return typeNumber < 4;
}

function parseIIN(numbers) {
  let year = '';
  let sex = false;
  const month = parseInt(`${numbers[2]}${numbers[3]}`, 10) - 1;
  if (!inRange(month, 0, 11)) {
    throw new Error('invalid month');
  }
  const day = parseInt(`${numbers[4]}${numbers[5]}`, 10);
  if (!inRange(numbers[6], 1, 6)) {
    throw new Error('invalid sex or epoch');
  }
  switch (numbers[6]) {
    case 1: {
      sex = true;
    }
    // eslint-disable-next-line no-fallthrough
    case 2: {
      year = `18${numbers[0]}${numbers[1]}`;
      break;
    }
    case 3: {
      sex = true;
    }
    // eslint-disable-next-line no-fallthrough
    case 4: {
      year = `19${numbers[0]}${numbers[1]}`;
      break;
    }
    case 5: {
      sex = true;
    }
    // eslint-disable-next-line no-fallthrough
    case 6: {
      year = `20${numbers[0]}${numbers[1]}`;
      break;
    }
  }
  const isLeapYear = parseInt(year, 10) % 4 === 0;
  if (
    !inRange(day, 1, 31) ||
    (isLeapYear && month === 1 && !inRange(day, 1, 29))
  ) {
    throw new Error('invalid day');
  }
  return {
    birthDate: new Date(parseInt(year, 10), month, day),
    sex,
  };
}

function parseBIN(numbers) {
  const month = parseInt(`${numbers[2]}${numbers[3]}`, 10) - 1;
  if (!inRange(month, 0, 11)) {
    throw new Error('invalid month');
  }

  return true
}

export function validateINN(params) {
  const INNumbers = getNumberList(params.identificationNumber);
  const isValidSequence = validateSequence(INNumbers);
  if (!isValidSequence) {
    return false;
  }
  let result = false;
  try {
    if (isIIN(INNumbers[4])) {
      result = parseIIN(INNumbers);
    } else {
      result = parseBIN(INNumbers);
    }
    if (!params.details) {
      result = true;
    }
  } catch (e) {
    if (params.debug) {
      console.log(e);
    }
    result = false;
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return result;
  }
}
