<template>
  <div class="loader-dots">
    <span class="loader-dots__dot"><br></span>
    <span class="loader-dots__dot"><br></span>
    <span class="loader-dots__dot"><br></span>
  </div>
</template>

<script>
export default {
  name: 'LoaderDotsComponent',
};
</script>
