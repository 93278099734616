<template>
  <div v-if="suggestions" class="location-selection">
    <div class="container--modal location-selection__container">
      <!--      <div class="location-selection__header">-->
      <!--        <button class="location-selection__back" aria-label="Назад" @click="close()">-->
      <!--          <span class="visually-hidden">Назад</span>-->
      <!--        </button>-->
      <!--      </div>-->
      <div>
        <!--        <div v-if="getCountriesList.length > 1" class="mb_xs">-->
        <!--          <CustomSelect-->
        <!--            v-model="currentCountry"-->
        <!--            label-option-name="label"-->
        <!--            :options="getCountriesList"-->
        <!--            placeholder="Выберите страну"-->
        <!--            small-->
        <!--          />-->
        <!--        </div>-->
        <div class="location-selection__search">
          <input
            v-model="searchString"
            class="location-selection__search-input"
            placeholder="Поиск"
          />
        </div>
      </div>
      <div class="location-selection__title">
        Выберите город
      </div>
      <ul
        v-if="suggestions.length"
        class="location-selection__list"
      >
        <div v-if="isAddressPending" class="location-selection__list-dots">
          <LoaderDots class="cart__loader" />
        </div>
        <li v-for="suggestion in suggestions" :key="suggestion.value" class="location-selection__item">
          <button
            class="location-selection__button font font_m"
            :class="{
              'font_medium location-selection__button_selected': getSelectedCity === suggestion.data.city,
            }"
            type="button"
            @click="selectCityHandler(suggestion)"
          >
            <template v-if="suggestion.data.settlement_with_type">
              suggestion.data.settlement_with_type
              {{ suggestion.data.settlement_with_type }},
              {{ suggestion.data.region_with_type }}
            </template>
            <template v-else-if="suggestion.data.city">
              {{ suggestion.data.city }}
            </template>
          </button>
        </li>
      </ul>
      <p v-else class="location-selection__list--empty">
        Для вашего запроса не найдено результатов
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import * as amplitude from '@amplitude/analytics-browser';
import _debounce from 'lodash/debounce';
import { suggestionTypes } from '@/plugins/enums/suggestions';
import env from '@/.env.json';
import './index.css';
import LoaderDots from '~/components/elements/LoaderDots';
// import CustomSelect from '~/components/elements/CustomSelect';

export default {
  name: 'LocationSelection',
  components: {LoaderDots},
  props: {
    title: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: env.COUNTRY,
    },
  },
  data() {
    return {
      focus: false,
      searchString: null,
      suggestions: [],
      isAddressPending: false,
    };
  },
  computed: {
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
    ...mapState({
      checkoutData: 'checkout/checkoutData',
    }),
    ...mapState('geolocation', [
      'visibleLocationModal',
      'selectedCountry',
    ]),
    currentCountry: {
      get() {
        return this.selectedCountry
      },
      set(value) {
        this.setSelectedCountry(value)
      },
    },
  },
  watch: {
    searchString(value) {
      this.debounce_onCityInputChange(value);
    },
    visibleLocationModal(value) {
      value && !this.suggestions.length && this.onCityInputChange()
    },
    selectedCountry() {
      this.onCityInputChange()
    },
  },
  beforeMount() {
    // this.setDefaultCountry()
    this.debounce_onCityInputChange = _debounce(this.onCityInputChange, 300);
  },
  methods: {
    ...mapActions({
      setSelectedCity: 'geolocation/setSelectedCity',
      findAddress: 'geolocation/findAddress',
      setAddress: 'checkout/setAddress',
      setIsConfirmedCity: 'geolocation/setIsConfirmedCity',
      selectCity: 'geolocation/selectCity',
    }),
    ...mapMutations({
      setSelectedCountry: 'geolocation/SET_SELECTED_COUNTRY',
    }),
    async onCityInputChange(query = '') {
      try {
        const suggestQuery = query || suggestionTypes.CITY;
        const { suggestions } = await this.findAddress({
          type: suggestionTypes.CITY,
          bound: suggestionTypes.CITY,
          query: suggestQuery,
          count: 20,
          locations: [{ country_iso_code: this.getSelectedCountry.value }],
        });

        const cities = []

        suggestions.every(item => {
          if (cities.findIndex(i => i.data.city === item.data.city) === -1) {
            cities.push(item)
          }

          return cities.length < 9
        })


        // this.suggestions = suggestions;
        this.suggestions = cities;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async selectCityHandler(suggestion) {
      if (this.isAddressPending) return;
      this.isAddressPending = true;

      await this.selectCity({
        suggestion,
        locations: [ { country_iso_code: this.getSelectedCountry.value } ],
      })

      const identify = new amplitude.Identify().set('user_city', this.getSelectedCity);
      amplitude.identify(identify);
      this.isAddressPending = false;
      this.close();
    },
    close() {
      this.$emit('close');
    },
    setDefaultCountry() {
      const code = this.defaultCountry
      const item = this.getCountriesList.find(item => item.value.toLowerCase() === code.toLowerCase())

      if (item) {
        this.setSelectedCountry(item)
      }
    },
  },
};
</script>
