<template>
  <div class="menu__catalog" :class="{'menu__catalog--scroll': isScrollOffset}">
    <div class="menu__catalog-content">
      <div class="container menu__catalog-container">
        <ul class="menu__catalog-list">
          <li
            v-for="(category, idx) in allCategories.filter((item) => item.code !== 'null-item')"
            :key="category.id"
            class="menu__catalog-item"
            @mouseenter="handleHoverItem(category, idx)"
          >
            <nuxt-link
              class="menu__catalog-item-link"
              :class="{
                'font_medium': firstSelectedIdx === idx,
              }"
              :to="`/catalog/${category.code}`"
            >
              {{ category.name }}
              <span v-if="category.items && category.items.length > 0" class="menu__catalog-item-expand">
                <svg-icon name="chevron-right" width="16" height="16" />
              </span>
            </nuxt-link>
          </li>
        </ul>
        <ul class="menu__catalog-list">
          <li
            v-for="(category, idx) in secondList"
            :key="category.id"
            class="menu__catalog-item"
            @mouseenter="handleHoverSecondItem(category, idx)"
          >
            <nuxt-link
              class="menu__catalog-item-link"
              :class="{
                'font_medium': secondSelectedIdx === idx,
              }"
              :to="`/catalog/${category.code}`"
            >
              {{ category.name }}
              <span v-if="category.items && category.items.length > 0" class="menu__catalog-item-expand">
                <svg-icon name="chevron-right" width="16" height="16" />
              </span>
            </nuxt-link>
          </li>
        </ul>
        <ul class="menu__catalog-list">
          <li
            v-for="category in thirdList"
            :key="category.id"
            class="menu__catalog-item"
          >
            <nuxt-link class="menu__catalog-item-link menu__catalog-item-link-hover" :to="`/catalog/${category.code}`">
              {{ category.name }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="menu__catalog-backdrop"
      @mouseenter="handleBackdropMouseenter"
    ></div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'HeaderBottomCatalogSider',
  data() {
    return {
      firstSelectedIdx: -1,
      secondSelectedIdx: -1,
      secondList: [],
      thirdList: [],
      isScrollOffset: false,
    };
  },
  computed: {
    ...mapState('catalog', ['allCategories']),
    ...mapGetters('catalog', ['getIsShowCategories']),
  },
  watch: {
    getIsShowCategories(val) {
      if (!val) {
        this.firstSelectedIdx = -1;
        this.secondSelectedIdx = -1;
        this.secondList = [];
        this.thirdList = [];
      }
    },
  },
  mounted() {
    const self = this;
    window.addEventListener('scroll', function() {
      self.isScrollOffset = window.scrollY > 110;
    });
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
    handleHoverItem(item, idx) {
      if (item.items && item.items.length > 0) {
        this.secondList = item.items;
        this.thirdList = [];
        this.secondSelectedIdx = -1;
      }
      this.firstSelectedIdx = idx;
    },
    handleHoverSecondItem(item, idx) {
      if (item.items && item.items.length > 0) {
        this.thirdList = item.items;
      }
      this.secondSelectedIdx = idx;
    },
    handleBackdropMouseenter() {
      this.$emit('backdropMouseenter');
    },
  },
}
</script>
