<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_center font_m font_bold">
          Забыли пароль?
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon name="close" width="15" height="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <form class="sign-up__form" @submit.prevent="onSubmitPassword">
        <div class="sign-up__form-control">
          <PasswordInput
            key="login-password"
            v-model="password"
            class="sign-up__form-input font_title-m"
            placeholder="Введите пароль"
            :show-error="errors.password"
            :error="errors.password"
            @focus="handleFocusPassword"
          >
            Пароль
          </PasswordInput>
        </div>
        <div class="sign-up__form-control">
          <PasswordInput
            key="login-password"
            v-model="passwordRepeat"
            class="sign-up__form-input font_title-m"
            placeholder="Введите пароль"
            :show-error="errors.passwordRepeat"
            :error="errors.passwordRepeat"
            @focus="handleFocusPasswordRepeat"
          >
            Подтвердить пароль
          </PasswordInput>
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isStartPhone || !isSocialAuth"
            size="xl"
            full
            type="submit"
            :disabled="isDisabledNextButton"
            data-test="get-code"
          >
            Установить пароль
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import env from '@/.env.json';
import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
import Button from '@/components/elements/Button';
import PasswordInput from '@/components/elements/PasswordInput';

export default {
  name: 'SignInSetPassword',
  components: {
    Button,
    PasswordInput,
  },
  data() {
    return {
      authModalPages,
      password: '',
      passwordRepeat: '',
      isDisabledGetCodeBtn: false,
      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
      errors: {
        password: null,
        passwordRepeat: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal', 'loginPhoneNumber']),
    phoneError() {
      let text = '';

      if (!this.isStartPhone) {
        if (!this.validatedPhone.isValid) text = validationMessages.ERROR_PHONE_INPUT;
      }

      return text;
    },
    isDisabledNextButton() {
      return false;
    },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
    }),
    ...mapActions('auth', ['resetPassword']),
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    // async goToSendingSms() {
    //   try {
    //     this.isDisabledGetCodeBtn = true;
    //     const data = {
    //       phone: this.validatedPhone.number,
    //     };
    //     const { isNew } = await this.loginByPhoneSendSms(data);
    //     this.setNewUser(isNew)
    //     this.setAcceptedPhone(this.validatedPhone.international);
    //     this.setCurrentAuthModalPage(authModalPages.ENTER_PASSWORD);
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     this.isDisabledGetCodeBtn = false;
    //   }
    // },
    async onSubmitPassword() {
      this.errors = {
        password: null,
        passwordRepeat: null,
      }
      if (!this.password) {
        this.errors = {
          ...this.errors,
          password: 'Обязательное поле',
        }
      }
      if (this.password !== this.passwordRepeat) {
        this.errors = {
          ...this.errors,
          passwordRepeat: 'Пароли не совпадают',
        }
      }
      if (this.errors.password || this.errors.passwordRepeat) {
        return;
      }
      try {
        this.isDisabledSubmitButton = true;
        const data = {
          phone: this.validatedPhone.number,
          password: this.password,
        }
        await this.resetPassword(data);
        this.setCurrentAuthModalPage(authModalPages.INDEX);
      } catch(error) {
        console.log(error);
      } finally {
        this.isDisabledSubmitButton = false;
      }
    },
    handleForgotPassword() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
    handleFocusPassword() {
      this.errors = {
        ...this.errors,
        password: null,
      };
    },
    handleFocusPasswordRepeat() {
      this.errors = {
        ...this.errors,
        passwordRepeat: null,
      };
    },
  },
};
</script>
