<template>
  <div class="social">
    <ul class="footer-nav__menu">
      <li
        v-for="(itemSocial, itemSocialIndex) in social"
        :key="`item-social-${itemSocialIndex}`"
        class="footer-nav__menu-item"
      >
        <a
          :href="itemSocial.href"
          :title="itemSocial.title"
          class="social__link"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          <SvgIcon
            :name="`socials/${itemSocial.icon}`"
            width="24"
            height="24"
            class="social__ico"
          />
          {{ itemSocial.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import env from '@/.env.json';

export default {
  name: 'Support',
  data() {
    return {
      env,
    }
  },
  computed: {
    social() {
      const social = []

      // if (this.env.FOOTER_PHONE) {
        social.push({
          id: 1,
          icon: 'support_phone',
          title: '+7 700 000 27 27',
          // href: this.env.FOOTER_PHONE,
          href: 'tel:+77000002727',
        })
      // }

      // if (this.env.FOOTER_WHATSAPP) {
        social.push({
          id: 1,
          icon: 'support_whatsapp',
          title: 'WhatsApp',
          // href: this.env.FOOTER_WHATSAPP,
          href: '/',
        })
      // }

      // if (this.env.FOOTER_EMAIL) {
        social.push({
          id: 2,
          icon: 'support_email',
          title: 'Почта',
          // href: this.env.FOOTER_EMAIL,
          href: '/',
        })
      // }

      return social
    },
  },
};
</script>
