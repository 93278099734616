const DEFAULT_REVIEWS_PAGE_SIZE = 5;
const DEFAULT_PAGE = 1;

export const state = () => ({
  reviews: [],
  range: 0,
  rating: 0,
  activePage: 0,
  isLoading: {
    reviewsAdd: false,
    reviewsData: false,
    reviewsVote: false,
    moreReviews: false,
  },
});

export const getters = {
  pagesCount: (state) => Math.ceil(state.range / DEFAULT_REVIEWS_PAGE_SIZE),
}

export const mutations = {
  SET_REVIEWS_DATA(state, payload = {}) {
    state.range = payload?.range || 0;
    state.rating = payload?.rating || 0;
    state.activePage = payload?.page || 0;
    state.reviews = payload?.items || [];
  },
  SET_REVIEWS_MORE(state, payload = {}) {
    state.activePage = payload?.page || 0;
    state.reviews.push(...(payload?.items || []));
  },
  SET_LOADING(state, payload) {
    Object.keys(payload).forEach(key => {
      state.isLoading[key] = payload[key]
    })
  },
}

export const actions = {
  async fetchReviewsData({ commit }, { type, code, filter = {}, showMore = false}) {
    const params = {
      object_type: type,
      object_code: code,
      sort_field: filter?.sortField || 'created_at',
      sort_direction: filter?.sortDirection || 'desc',
      page: filter?.page || DEFAULT_PAGE,
      per_page: filter?.perPage || DEFAULT_REVIEWS_PAGE_SIZE,
    }

    const loading = { reviewsData: true }
    showMore && (loading.moreReviews = true)

    commit('SET_LOADING', loading)

    try {
      const { rating, reviewsCount: range, reviews: items } = await this.$api.reviews.getAllReviews(params);

      if (showMore) commit('SET_REVIEWS_MORE', { items, page: params.page });
      else commit('SET_REVIEWS_DATA', { items, range, rating, page: params.page });
    } catch (error) {
      console.log(error)
    } finally {
      commit('SET_LOADING', { reviewsData: false, moreReviews: false })
    }
  },
  async createReview({ commit, dispatch }, { type, code, formData }) {
    try {
      commit('SET_LOADING', { reviewsAdd: true })
      await this.$api.reviews.createReview(formData)
      dispatch('fetchReviewsData', { type, code });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('SET_LOADING', { reviewsAdd: false })
    }
  },
  async changeReviewVote({ commit }, { id, opinion }) {
    try {
      commit('SET_LOADING', { reviewsVote: true })
      await this.$api.reviews.changeReviewVote(id, opinion);
    } catch (error) {
      console.log(error)
    } finally {
      commit('SET_LOADING', { reviewsVote: false })
    }
  },
}
