<template>
  <div class="container header-main">
    <AddToFavCartPopupSelect />
    <AddedFavCartPopup :display="!mqIsMobile" />
    <button
      v-if="showCategories"
      class="header-main__back"
      type="button"
      @click="showCategories = false"
    >
      Каталог
    </button>
    <div
      v-else
      class="header-main__menu-left"
    >
      <MenuToggle
        v-model="isOpenedMobileMenu"
        class="header-main__menu-toggle header-main__menu-toggle-mobile"
        @changeMobileMenu="onChangeMobileMenu"
      >
      </MenuToggle>
      <button
        class="header-user__button header-user__button-mobile"
        data-test="show-search"
        aria-label="Показать поиск"
        @click="showSearch"
      >
        <svg-icon name="site-header/search-small" width="21" height="21" />
      </button>
    </div>

    <HeaderMainSearch :is-scroll-offset="isScrollOffset" />
    <HeaderMainMenu
      v-model="showCategories"
    />
    <!--    <MenuToggle-->
    <!--      v-model="showCategories"-->
    <!--      :hide-text-on-start="false"-->
    <!--      class="header-main__menu-toggle-desktop"-->
    <!--    >-->
    <!--      Категории-->
    <!--    </MenuToggle>-->
    <HeaderMainActions />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle.vue';
import HeaderMainActions from '~/layouts/components/Header/Main/Actions/index.vue';
import HeaderMainSearch from '~/layouts/components/Header/Main/Search';
import HeaderMainMenu from '~/layouts/components/Header/Main/Menu/index.vue';
import AddedFavCartPopup from '~/components/elements/AddedFavCartPopup.vue';
import AddToFavCartPopupSelect from '~/components/elements/AddToFavCartPopupSelect.vue';

export default {
  name: 'HeaderMain',
  components: {
    AddToFavCartPopupSelect,
    AddedFavCartPopup,
    HeaderMainMenu,
    HeaderMainSearch,
    HeaderMainActions,
    MenuToggle,
  },
  props: {
    openedMobileMenu: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ...mapState('catalog', ['isShowCategories']),
    };
  },
  computed: {
    ...mapGetters('catalog', ['getIsShowCategories']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    showCategories: {
      get() {
        return this.getIsShowCategories;
      },
      set(value) {
        this.setIsShowCategories(value);
      },
    },
    isOpenedMobileMenu: {
      get() {
        return this.openedMobileMenu
      },
      set(value) {
        this.$emit('changeMobileMenu', value)
      },
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch']),
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
    onChangeMobileMenu() {
      this.$emit('changeMobileMenu');
    },
    showSearch() {
      this.setIsOpenedSearch(true);
    },
  },
}
</script>

