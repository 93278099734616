<template>
  <div class="layout-error container">
    <header class="layout-error__header">
      <!--<Logo />-->
    </header>
    <main>
      <template v-if="error.statusCode === 404">
        <h1 class="layout-error__title layout-error__title--desktop">
          Страница не найдена
        </h1>
        <NotFound />
      </template>

      <template v-else>
        <h1 class="layout-error__title">
          Произошла ошибка
        </h1>
        <Button to="/">
          Вернуться на главную
        </Button>
      </template>
    </main>
  </div>
</template>

<script>
// import Logo from '@/components/elements/Logo';
import NotFound from '@/components/elements/NotFound';
import Button from '@/components/elements/Button';
import login from '~/mixins/login';
import accountChange from '~/mixins/accountChange';

export default {
  name: 'Error',
  components: {
    // Logo,
    NotFound,
    Button,
  },
  mixins: [login, accountChange],
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
