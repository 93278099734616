<template>
  <vue-scroll :ops="scrollOptions">
    <slot />
  </vue-scroll>
</template>

<script>
export default {
  name: 'CustomScroll',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const defaultOptions = {
      vuescroll: {
        mode: 'native',
        sizeStrategy: 'percent',
        detectResize: false,
        onlyShowBarOnScroll: false,
        /** Enable locking to the main axis if user moves only slightly on one of them at start */
        locking: true,
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#000',
        opacity: 1,
        hoverStyle: false,
        specifyBorderRadius: false,
        size: '2px',
        disable: false,
        zooming: false,
      },
      rail: {
        background: 'rgba(0, 0, 0, 0)',
        size: '2px',
        specifyBorderRadius: '0px',
        keepShow: false,
        gutterOfSide: '0',
        gutterOfEnds: '0',
      },
    }

    return {
      defaultOptions,
      scrollOptions: {},
    }
  },
  created() {
    Object.assign(this.scrollOptions, this.defaultOptions, this.options)
  },
}
</script>
