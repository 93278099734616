<template>
  <Nuxt />
</template>

<script>
import accountChange from '~/mixins/accountChange';
import login from '~/mixins/login';

export default {
  name: 'ErrorLayout',
  mixins: [login, accountChange],
}
</script>
