<template>
  <div class="company__add">
    <div v-if="title" class="company__add-title font font_m font_uppercase font_medium">
      Добавить свою компанию
    </div>
    <div v-if="!mqIsMobile" class="company__add-subtitle font font_m font_grey">
      БИН организации, которая будет платить за заказ
    </div>

    <div class="company__add-search">
      <CustomInput
        v-model="form.inn"
        class="company__add-search-field input--secondary"
        :float="!mqIsMobile"
        :error="errorInnHandler"
        :show-error="true"
        :placeholder="innPlaceholder"
        @input="onInnChange"
        @focus="onFocusHandler"
      >
        <template v-if="!mqIsMobile">
          БИН
        </template>
        <template v-else>
          БИН организации, которая будет платить за заказ
        </template>
      </CustomInput>
      <CustomPopup
        class="search__popup company__add-search-popup"
        :pop-up-state="isOpenedInn"
        @closePopUp="modalStateHandler(false)"
      >
        <CompanySearch :suggestions="searchResult.companies" @onClick="onClickSearch" />
      </CustomPopup>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {iin} from '~/plugins/validation/requisites';
import CustomInput from '~/components/elements/CustomInput';
import CustomPopup from '~/components/elements/CustomPopup';
import CompanySearch from '~/components/profile/company/Search';

export default {
  name: 'CompanyAdd',
  components: {CompanySearch, CustomPopup, CustomInput},
  props: {
    title: {
      type: Boolean,
      default: true,
    },
  },
  validations: {
    form: {
      inn: {
        iin,
      },
    },
  },
  data() {
    return {
      form: {
        inn: null,
      },
      customInnError: '',
      isOpenedInn: false,
      searchResult: {
        companies: [],
      },
      maxSuggestion: 300,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    errorInnHandler() {
      let text = '';
      const inn = this.$v.form.inn;

      if (!this.form.inn) {
        return ''
      }

      text = this.getInputError(inn,text = 'Неверный БИН')

      if (this.customInnError) text = this.customInnError

      if (text) {
        this.clearSearchResult(true)
      }

      return text;
    },
    innPlaceholder() {
      return this.mqIsMobile ? 'БИН' : ''
    },
  },
  methods: {
    ...mapActions({
      addUserCompany: 'profile/addUserCompany',
    }),
    async findCompany(query, kpp = '', count = 5) {
      try {
        const response = await this.$api.kompra.findCompany(query)

        if (!response?.bin) return this.setInnError('БИН не существует');


        this.showSearchResult([ this.suggestionFactory(response) ])
      } catch (error) {
        console.log(error)
      }
    },
    async addCompany(company) {
      await this.addUserCompany(company)
      this.$emit('add')
    },
    getInputError(validate, err) {
      if (!validate.$model) return ''

      if (validate.$invalid) return err

      return ''
    },
    onInnChange(value) {
      this.form.inn = value;
      this.$v.form.$touch();
      this.setInnError('')

      if (!this.$v.form.inn.$invalid) this.findCompany(value, '', this.maxSuggestion);
    },
    onFocusHandler() {
      if (this.searchResult.companies.length) {
        setTimeout(() => {
          this.modalStateHandler(true)
        }, 300)
      }
    },
    setInnError(err) {
      this.customInnError = err
    },
    modalStateHandler(state) {
      this.isOpenedInn = state
    },
    onClickSearch(company) {
      this.addCompany(company)
      this.clearSearchResult(true)
    },
    showSearchResult(companies) {
      this.searchResult.companies = companies
      this.modalStateHandler(true)
    },
    clearSearchResult(close = false) {
      close && this.modalStateHandler(false)

      this.searchResult = {
        companies: [],
      }
    },
    suggestionFactory(suggestion) {
      return {
        name: suggestion?.name || '',
        inn: suggestion?.bin || '',
        kpp: '',
        address: suggestion?.lawAddress|| '',
      }
    },
  },
}
</script>
