<template>
  <div class="header-bottom" :class="{ 'header-bottom--scroll' : isScrollOffset }">
    <div class="container header-bottom__container">
      <nav class="menu font">
        <HeaderBottomMenu v-model="showCategories" :is-scroll-offset="isScrollOffset" />

        <transition name="fade">
          <HeaderBottomCatalog
            v-show="showCategories"
            @linkClick="onLinkClick"
          />
        </transition>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import HeaderBottomMenu from '~/layouts/components/Header/Bottom/Menu.vue';
import HeaderBottomCatalog from '~/layouts/components/Header/Bottom/Catalog.vue';

export default {
  name: 'HeaderBottom',
  components: {
    HeaderBottomCatalog,
    HeaderBottomMenu,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ...mapState('catalog', ['isShowCategories']),
    }
  },
  computed: {
    showCategories: {
      get() {
        return this.isShowCategories;
      },
      set(value) {
        this.setIsShowCategories(value);
      },
    },
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
  },
}
</script>

