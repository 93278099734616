<template>
  <div class="profile-initials" @click="$emit('click')">
    <div class="profile-initials__wrapper">
      <div class="profile-initials__icon" :class="iconClass">
        <SvgIcon
          :name="'profile'"
          :width="initialsIcon ? 14 : 30"
          :height="initialsIcon ? 14 : 30"
        />
      </div>
      <div class="profile-initials__name" :class="nameClass">
        {{ userFullname }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileInitials',
  props: {
    initialsIcon: {
      type: String,
      default: '',
    },
    initials: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    nameClass: {
      type: String,
      default: '',
    },
    initialsClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userFullname: '',
    }
  },
  watch: {
    initialsIcon(value) {
      return value ?? 'profile';
    },
    name(value) {
      this.userFullname = value;
    },
  },
}
</script>

