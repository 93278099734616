const { XMLParser, XMLBuilder } = require('fast-xml-parser');

class XML {
  constructor() {
    this.parser = new XMLParser()
    this.builder = new XMLBuilder()
  }

  build(data = {}) {
    return this.builder.build(data)
  }

  parse(data) {
    return this.parser.parse(data)
  }
}

export const xml = new XML()


