import { receiveMethods } from '@/plugins/enums/checkout';
import {
  orderStatus,
  deliveryStatus,
  orderPaymentStatus,
  cancelReasons,
  filterField,
  businessTypeName,
} from '@/plugins/enums/order';
import { dateToString } from '@/plugins/formatting';
import {referralOrderSortFields, referralSourceName} from '~/plugins/enums/profile';
import {sortDirections} from '~/plugins/enums/sort';
import {getLocaleDate} from '~/plugins/helpers/dates';

export function getOrderStatusName(status) {
  switch (status) {
    case orderStatus.PRE_ORDER:
      return 'Предзаказ: ожидаем поступления товара';
    case orderStatus.CREATED:
      return 'Оформлен';
    case orderStatus.AWAITING_CHECK:
      return 'В обработке';
    case orderStatus.CHECKING:
      return 'В обработке';
    case orderStatus.AWAITING_CONFIRMATION:
      return 'В обработке';
    case orderStatus.IN_PROCESSING:
      return 'В обработке';
    case orderStatus.TRANSFERRED_TO_DELIVERY:
      return 'В обработке';
    case orderStatus.DELIVERING:
      return 'В процессе доставки';
    case orderStatus.READY_FOR_RECIPIENT:
      return 'В пункте выдачи';
    case orderStatus.DONE:
      return 'Доставлен';
    case orderStatus.RETURNED:
      return 'Возвращен';
  }
}

export function getOrderDeliveryMethodName(method) {
  switch (method) {
    case receiveMethods.DELIVERY:
      return 'Доставка курьером';
    case receiveMethods.PICKUP:
      return 'Доставка в пункт выдачи';
    case receiveMethods.EXPRESS:
      return 'Экспресс доставка';
  }
}

export function generateThankPageUrl(orderId) {
  return `${document.location.origin}/thank-you/${orderId}`;
}

export function getCancelReasonName(reason) {
  switch (reason) {
    case cancelReasons.DELIVERY_VIOLATED:
      return 'Нарушен срок доставки';
    case cancelReasons.CHANGE_MIND:
      return 'Передумал';
    case cancelReasons.CHEAPER:
      return 'Нашел дешевле';
    case cancelReasons.FASTER:
      return 'Нашел доставку быстрее';
    case cancelReasons.ANOTHER:
      return 'Другое';
  }
}

export function getPaymentStatusName(status) {
  switch (status) {
    case orderPaymentStatus.PAID:
      return 'Оплачен';
    case orderPaymentStatus.TIMEOUT:
      return 'Просрочен';
    case orderPaymentStatus.HOLD:
      return 'Оплачен';
    case orderPaymentStatus.ERROR:
      return 'Ошибка';
    case orderPaymentStatus.WAITING:
      return 'Не оплачен';
    case orderPaymentStatus.RETURNED:
      return 'Оплата возвращена';
    default:
      return 'Не оплачен';
  }
}

export function getDeliveryStatusName(status) {
  switch (status) {
    case deliveryStatus.STATUS_ON_POINT_IN:
      return 'Принят на склад в пункте отправления';
    case deliveryStatus.STATUS_ON_WAY:
      return 'В пути';
    case deliveryStatus.STATUS_ON_POINT_OUT:
      return 'Прибыл на склад в пункте назначени';
    case deliveryStatus.STATUS_DELIVERING:
      return 'Передана на доставку в пункте назначения';
    case deliveryStatus.STATUS_READY_FOR_RECIPIENT:
      return 'Готов к выдаче в пункте назначения';
    case deliveryStatus.STATUS_DONE:
      return 'Доставлен получателю';
    case deliveryStatus.STATUS_RETURNED_FROM_DELIVERY:
      return 'Возвращен с доставки';
    case deliveryStatus.STATUS_PARTIAL_RETURN:
      return 'Частичный возврат';
    case deliveryStatus.STATUS_RETURN_READY:
      return 'Подготовлен возврат';
    case deliveryStatus.STATUS_RETURNING:
      return 'Возвращается отправителю';
    case deliveryStatus.STATUS_RETURNED:
      return 'Возвращен отправителю';
    case deliveryStatus.STATUS_LOST:
      return 'Утеряна';
    case deliveryStatus.STATUS_PROBLEM:
      return 'Возникла проблема';
    case deliveryStatus.STATUS_CANCEL:
      return 'Отменена';
    case deliveryStatus.STATUS_UNKNOWN:
      return 'Неизвестный статус';
    case deliveryStatus.STATUS_NA:
      return 'N/A';
    default:
      return 'Создан';
  }
}

export function getOrderFilterDate(field) {
  const getDate = (f) => {
    const today = new Date();
    switch (f) {
      case filterField.YEAR:
        return new Date(today.setFullYear(today.getFullYear() - 1));
      case filterField.MONTH:
        return new Date(today.setMonth(today.getMonth() - 1));
      case filterField.DAY:
        return new Date(today.setDate(today.getDate() - 1));
      case filterField.ALL_TIME:
      default:
        return null;
    }
  };

  return getDate(field) ? dateToString(getDate(field)) : null;
}

export function getReferralOrdersParams(query) {
  const {
    page = 1,
    orderField = referralOrderSortFields.ORDER_DATE,
    orderDirection = sortDirections.DESC,
    orderFilterField,
  } = query

  const date = getOrderFilterDate(orderFilterField);

  return { page, orderField, orderDirection, orderFilterField, date }
}

export function getReferralOrders(items, isReferralId = true) {
  return items.map(i => {
    // eslint-disable-next-line camelcase
    const { name, image, order_date, source, category_code, code, variantGroup } = i;
    // eslint-disable-next-line camelcase
    const date = order_date && getLocaleDate(order_date)

    let values = null;
    if (variantGroup) {
      const { characteristics = [], combinations = [] } = variantGroup;
      const { props } = combinations.find((c) => c.code === code);
      const keys = Object.keys(props);
      values = keys.map((k) => {
        const { options, name } = characteristics.find((c) => c.code === k);
        const option = options.find((o) => o.value === props[k]);
        return `${name}: ${option.name}`;
      });
    }

    const note = values && values.join(', ');

    return {
      id: i.id,
      source,
      product: {
        name,
        category_code,
        code,
        note,
        image: { ...image },
      },
      info: [
        { id: 0, name: `${Number(i.qty)} шт`, link: null, thData: 'Количество' },
        { id: 1, name: `${businessTypeName[i.business_type] ? businessTypeName[i.business_type] : '-'}`,
          link: null, thData: 'Тип бизнеса' },
        (isReferralId && { id: 2, name: i.customer_id, thData: 'ID реферала', link: {
            name: 'profile.referal.id',
            params: { id: i.customer_id },
          },
        }),
        { id: 3, name: referralSourceName[source], link: null, thData: 'Источник' },
        { id: 4, name: date, link: null,  thData: 'Дата заказа'},
        { id: 5, name: i.price_product.value, currency: true, link: null, thData: 'Сумма' },
        { id: 6, name: i.price_commission.value, currency: true, link: null, thData: 'Выплата' },
      ],
    }
  });
}
