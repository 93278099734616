<template>
  <transition name="fade">
    <div
      class="alert"
      :class="{
        'alert--centered': centered,
        'alert--error': type === 'error',
        'alert--success': type === 'success',
        'alert--card': icon === 'card',
        'alert--lg': lg,
        'alert--sm': sm,
        'alert--md': md,
      }"
    >
      <p v-if="$slots.title" class="alert__text alert__title">
        <slot name="title" />
      </p>
      <div v-if="$slots.content" class="alert__text alert__content font font_m">
        <slot name="content" />
      </div>
      <button
        v-if="closable"
        class="alert__text alert__close"
        aria-label="Закрыть уведомление"
        @click="close()"
      >
        <span class="visually-hidden">Закрыть уведомление</span>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>

