<template>
  <div class="sign-in-buttons">
    <Button
      v-for="item in socialIcons"
      :key="item.id"
      only-icon
      :aria-label="item.label"
      :disabled="disabled"
      class="sign-in-buttons__item"
      @click="onLoginBySocial(item.name)"
    >
      <slot name="icon">
        <SvgIcon
          :name="`socials/${item.icon}`"
          width="24"
          height="24"
          class="social__ico"
        />
      </slot>
    </Button>
    <!--    <Button-->
    <!--      v-if="visibleSignin"-->
    <!--      theme="outline"-->
    <!--      class="sign-in-buttons__item sign-in-buttons__item-lg"-->
    <!--      @click.stop="toSignIn"-->
    <!--    >-->
    <!--      Войти по почте-->
    <!--    </Button>-->
    <div class="font font_m">
      Войти через соцсеть
    </div>
  </div>
</template>

<script>
import './index.css';
import { mapActions, mapMutations } from 'vuex';
import { authModalPages } from '@/plugins/enums/auth';
import Button from '@/components/elements/Button';

export default {
  name: 'SignInButtons',
  components: {
    Button,
  },
  props: {
    visibleSignin: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socialIcons: [
        // { id: 0, name: 'facebook', icon: 'facebook', label: 'Авторизоваться через facebook' },
        { id: 1, name: 'google', icon: 'google-bw', label: 'Авторизоваться через google' },
        // { id: 2, name: 'apple', icon: 'apple', label: 'Авторизоваться через apple' },
        { id: 3, name: 'vkontakte', icon: 'vkontakte', label: 'Авторизоваться через vkontakte' },
      ],
    }
  },
  methods: {
    ...mapActions('auth', [
      'getSocialLink',
    ]),
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
    }),
    async onLoginBySocial(driver) {
      try {
        const data = {
          backUrl: '/',
          driver,
          redirectUrl: `${document.location.origin}/social-login`,
        };
        const socialUrl = await this.getSocialLink(data);
        document.location.href = socialUrl;
      } catch (error) {
        return false;
      }
    },
    toSignIn() {
      this.setCurrentAuthModalPage(authModalPages.SIGN_IN);
    },
  },
};
</script>
