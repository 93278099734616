<template>
  <ul class="search-result__suggestions search-suggestions">
    <li
      v-for="(item, index) in suggestions"
      :key="index"
      class="search-suggestions__item font font_m"
      :class="[{ 'font_grey' : isString(item), 'search-suggestions__item_icon' : itemIcon }, itemClass]"
      @click="onClickSuggestion(item)"
    >
      <template v-if="isString(item)">
        {{ item }}
      </template>
      <template v-else>
        <div
          v-for="(suggestion, id) in item"
          :key="id"
          :class="suggestion.class"
        >
          {{ suggestion.name }}
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SearchResultSuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    itemIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isString(item) {
      return typeof item === 'string'
    },
    onClickSuggestion(item) {
      this.$emit('suggestion', item)
    },
  },
}
</script>
