<template>
  <nav
    class="footer-nav font"
    :class="{'footer-nav--opened': isOpenedMenu}"
  >
    <p v-if="!info.url" class="footer-nav__title font_sm font_bold font_uppercase">
      {{ info.name }}
    </p>
    <nuxt-link
      v-else
      :to="info.url"
      class="footer-nav__title font_sm font_bold font_uppercase"
    >
      {{ info.name }}
    </nuxt-link>
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <ul
        v-show="isOpenedMenu"
        ref="menu"
        class="footer-nav__menu"
      >
        <li
          v-for="(item, index) in info.children"
          :key="index"
          class="footer-nav__menu-item"
        >
          <nuxt-link :to="item.url" class="footer-nav__link font_m">
            {{ item.name }}
          </nuxt-link>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<script>
import './index.css';

export default {
  name: 'FooterNav',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpenedMenu: true,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  watch: {
    mqIsMobile(value) {
      if (!value) {
        this.isOpenedMenu = true;
        this.$refs.menu.style.height = 'auto';
      } else {
        this.isOpenedMenu = true;
      }
    },
  },
  mounted() {
    // this.isOpenedMenu = !this.mqIsMobile;
    this.isOpenedMenu = true;
  },
  methods: {
    toggleMenu() {
      // this.isOpenedMenu = !this.isOpenedMenu
      this.isOpenedMenu = true;
    },
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el, done) {
      this.$nextTick(() => {
        el.style.height = 0;
        setTimeout(done, 300);
      });
    },
  },
};
</script>
