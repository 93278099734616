<template>
  <div>
    Search Popup
  </div>
</template>
<script>
export default {
  name: 'SearchPopup',
}
</script>
