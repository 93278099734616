export default function ({ $axios, store }) {
  $axios.interceptors.response.use(
    async (response) => {
      if (response.status === 401 && store.state.auth.hasSession) {
        await store.dispatch('auth/logout');
      }

      return response;
    },

    (error) => Promise.reject(error),
  );
  $axios.interceptors.request.use(
    (request) => {
      return request;
    },
  );
  $axios.onRequest(config => {
    const ipAddress = $axios.defaults.headers.common['X-User-IP'];
    if (ipAddress) {
      config.headers['X-User-IP'] = ipAddress;
    }
    return config;
  });
}
